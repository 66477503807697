export const INTERVAL_CHART_DROPDOWN_TITLE = 'Finding Types';
export const INTERVAL_FH = 'FH';
export const INTERVAL_FC = 'FC';
export const INTERVAL_MONTH = 'MONTH';

export const CUMMULATIVE_INTERVAL_FH = 'CUMULATIVE_FH';
export const CUMMULATIVE_INTERVAL_FC = 'CUMULATIVE_FC';
export const CUMMULATIVE_INTERVAL_AGE = 'AGE';

export const INTERVAL_INFO_OPSFR = 'opsfr';
export const INTERVAL_INFO_WWFR = 'wwfr';
export const INTERVAL_INFO_FD = 'finding_description_count';

/* Raw task reports table */
export const DEFAULT_OFFSET = 0;
export const DEFAULT_LIMIT = 10;

export const DEFAULT_TABLE_DATA = {
  limit: DEFAULT_LIMIT,
  offset: DEFAULT_OFFSET,
  filterGroup: [],
};

export const OLD_ARCH = 'Old_Architecture';
export const NEW_ARCH = 'New_Architecture';
// for polling rebuild in repository module
export const RebuildPollingInterval = 20000;
