import { createSlice } from '@reduxjs/toolkit';
import {
  clearBuildInfoObjectReducer,
  clearFiltersObjectReducer,
  clearPrevBuildIdObjectReducer,
  clearSelectedTaskReducer,
  clearTasksObjectReducer, updateBuildInfoObjectReducer, updateBuildMetadataReducer,
  updateFetchedFiltersReducer,
  updateFetchedTasksListReducer,
  updateFetchedTasksReducer,
  updateSelectedFiltersReducer,
  updateTaskListSortReducer,
  updateTasksObjectReducer,
} from './programAnalysisReducers';
import {
  getBuild, getBuildMetadata, getPAFilters, postPAFilters,
} from './programAnalysisAsyncThunks';
import { initialState } from './constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
const programAnalysisSlice = createSlice({
  name: 'programAnalysis',
  initialState,
  reducers: {
    // sync action creators
    updatePAFilters: updateSelectedFiltersReducer,
    updateTasksObject: updateTasksObjectReducer,
    updateBuildInfoObject: updateBuildInfoObjectReducer,
    clearFilters: clearFiltersObjectReducer,
    clearTasks: clearTasksObjectReducer,
    clearSelectedTask: clearSelectedTaskReducer,
    updateTaskListSort: updateTaskListSortReducer,
    clearBuild: clearBuildInfoObjectReducer,
    clearPrevBuildId: clearPrevBuildIdObjectReducer,
  },
  // Async action creators
  extraReducers: (builder) => {
    builder.addCase(getPAFilters().fulfilled, updateFetchedFiltersReducer);
    builder.addCase(getPAFilters().rejected, updateFetchedFiltersReducer);
    builder.addCase(getPAFilters().pending, updateFetchedFiltersReducer);
    builder.addCase(postPAFilters().pending, updateFetchedTasksReducer);
    builder.addCase(postPAFilters().rejected, updateFetchedTasksReducer);
    builder.addCase(postPAFilters().fulfilled, updateFetchedTasksReducer);
    builder.addCase(getBuild().pending, updateFetchedTasksListReducer);
    builder.addCase(getBuild().rejected, updateFetchedTasksListReducer);
    builder.addCase(getBuild().fulfilled, updateFetchedTasksListReducer);
    builder.addCase(getBuildMetadata().pending, updateBuildMetadataReducer);
    builder.addCase(getBuildMetadata().rejected, updateBuildMetadataReducer);
    builder.addCase(getBuildMetadata().fulfilled, updateBuildMetadataReducer);
  },
});

export const {
  updatePAFilters,
  updateTasksObject,
  updateBuildInfoObject,
  clearFilters,
  clearTasks,
  clearSelectedTask,
  updateTaskListSort,
  clearBuild,
  clearPrevBuildId,
} = programAnalysisSlice.actions;
export const { reducer } = programAnalysisSlice;
export default programAnalysisSlice;
