import { Methods } from '../../utils/customHooks/apiTypes';

// API
export const TASK_REPORT_API_PATH = 'api/bcm/program-analysis/task-report-analysis';
export const TASK_REPORT_API_POST_METHOD = 'post' as Methods;
export const TASK_REPORT_PRECOMPUTE_API_PATH = 'api/precompute/scopes';
export const TASK_REPORT_API_GET_METHOD = 'get' as Methods;
// operatorcard path
export const TASK_REPORT_OPERATORCARD_API_PATH = 'data/operator-key-params?task_number';
export const TASK_REPORT_WORLDWIDEFLEETCARD_API_PATH = 'data/fleet-key-params?task_number';
// Slice
export const TASKS_SLICE = 'task';

// Datasets name
export const SMAC_POST_TASK_ASSESSMENT_RAW_TASK_REPORT = 'smac_post_task_assessment_raw_task_report';

// Async thunk actions
export const FETCH_TASK_INFO = 'fetchTaskInfo';
export const FETCH_TASK_INFO_TABLE_NAME = 'smac_mpd_task_metadata';
export const FETCH_TASK_INFO_COLUMNS = [
  'mpd_task_reference',
  'source_document_reference',
  'source_document_type',
  'mpd_task_description',
  'mpd_task_code',
  'mpd_100_threshold',
  'mpd_100_interval',
  'applicability_text',
  'is_calculable',
];

export const FETCH_TASK_REPORT_ANALYSIS = 'fetchTaskReportAnalysis';
export const FETCH_TASK_REPORT_ANALYSIS_TABLE_NAME = 'smac_task_report_analysis_operator_level_view';
export const FETCH_TASK_REPORT_ANALYSIS_COLUMNS = [
  'total_tr',
  'total_ac_reported',
  'sufficient_reported_data_for_optimization',
  'sufficent_reported_bar',
  'sufficient_task_report',
  'avg_yield',
];

export const FETCH_TASK_INFO_FLEET_LEVEL = 'fetchTaskInfoFleetLevel';
export const FETCH_TASK_INFO_FLEET_LEVEL_TABLE_NAME = 'smac_precompute_fleet_climatic_wwfr_finding_type';
export const FETCH_TASK_INFO_FLEET_LEVEL_COLUMNS = [
  'climate_fleet_finding_rate',
  'all_climate_regions',
  'fleet_finding_avg_count',
  'fleet_finding_list',
];

// precomp
export const FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION = 'fetchTaskInfoFleetLevelGeoRegion';
export const FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART = 'fetchTaskInfoFleetLevelFindingChart';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_REPORT_RECEIVED_YEARLY = 'fetchTaskInfoOperatorLevelReportReceivedYearly';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART = 'fetchTaskInfoOperatorLevelFindingChart';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN = 'fetchTaskInfoOperatorLevelFindingRateByMsn';
export const FETCH_TASK_REPORT_OPERATOR_CARD = 'fetchTaskReportOperatorCard';
export const FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD = 'fetchTaskReportWorldWideFleetCard';
export const FETCH_TASK_INFO_FLEET_LEVEL_LW = 'fetchTaskInfoFleetLevelCSMFiltered';
export const FETCH_TASK_INFO_FLEET_LEVEL_LW_TABLE_NAME = 'csm_mpd_filtered';
export const FETCH_TASK_INFO_FLEET_LEVEL_LW_COLUMNS = [
  'world_wide_finding_rate',
  'operator_finding_rate',
];

export const FETCH_TASK_INFO_OPERATOR_LEVEL = 'fetchTaskInfoOperatorLevel';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_TABLE_NAME = 'smac_task_report_analysis_operator_level';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_COLUMNS = [
  'operator_task_year',
  'operator_task_year_count',
  'operator_task_month',
  'operator_finding_list',
  'operator_finding_avg_count',
  'id_aircraft',
  'nil_finding_per_id_aircraft_count',
  'all_finding_per_id_aircraft_count',
  'finding_rate_per_id_aircraft_count',
  'ffr',
  'me',
];

export const FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL = 'fetchTaskReportByActualInterval';
export const FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL_TABLE_NAME = 'smac_task_report_analysis_operator_interval_findings';
export const FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL_COLUMNS = [
  'finding_range',
  'finding_type_description',
  'finding_count',
  'operator_interval_sfr',
  'fleet_interval_sfr',
  'graph',
];

export const FETCH_TASK_REPORT_BY_CUMMULATIVE_INTERVAL = 'fetchTaskReportByCummulativeInterval';
export const FETCH_TASK_REPORT_BY_CUMMULATIVE_INTERVAL_TABLE_NAME = 'smac_task_report_analysis_operator_cumulative_interval_findings';

export const START_RAW_TASK_REPORTS_SYNC = 'startRawTaskReportsSync';
export const FETCH_RAW_TASK_REPORTS_SYNC_STATUS = 'fetchRawTaskReportsSyncStatus';

export const FETCH_RAW_TASK_REPORTS = 'fetchRawTaskReports';
export const FETCH_RAW_TASK_REPORTS_COLUMN_SEARCH = 'fetchRawTaskReportsColumnSearch';
export const FETCH_PRECOMPUTE_RAW_TASK_REPORTS = 'fetchPrecomputeRawTaskReports';
export const FETCH_PRECOMPUTE_RAW_TASK_REPORTS_COLUMN_SEARCH = 'fetchPrecomputeRawTaskReportsColumnSearch';
export const FETCH_RAW_TASK_REPORTS_TABLE_NAME = 'smac_post_task_assessment_raw_task_report';
export const FETCH_RAW_TASK_REPORTS_COLUMNS = [
  'actual_interval_months',
  'actual_interval_flight_cycles',
  'actual_interval_flight_hours',
  'finding_type_description',
  'id_aircraft',
  'ac_series',
  'corrective_action',
];

export const FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL = 'fetchPrecompTaskReportByActualInterval';
