import { store } from '../../store/store';

// Check if the required SAS permission(s) is allowed for the user.
const checkSasPermission = (requiredSasPermissions: string[]) => {
  const allowedPermissions = store?.getState().userPermissions.sasPermissions;

  // Check if every required permission is allowed for the user.
  const result = requiredSasPermissions.every(
    (requiredPermission) => (
      // Check if the required permission is in the user's allowed permissions.
      allowedPermissions.some((permission) => permission.code === requiredPermission)),
  );

  return result;
};

// Check if the user has access to required feature(s).
const checkFeaturePermission = (requiredFeaturePermissions: string[]) => {
  const allowedFeatures = store?.getState().userPermissions.featurePermissions;

  // Check if every required feature is allowed for the user.
  const result = requiredFeaturePermissions.every(
    (requiredFeature) => (
      // Check if the required feature is in the user's allowed features.
      allowedFeatures.some((feature) => feature.featureCode === requiredFeature)),
  );

  return result;
};

// Switch between 2 features as per the required permissions.
// Returns allowedFeature when all the required permissions are present else it returns alternateFeature.
// allowedFeature and alternateFeature can be a component or a function.
const userPermissionFeatureSwitch = <Type>(
  requiredFeaturePermissions: string[],
  allowedFeature: Type,
  alternateFeature?: Type,
) => {
  if (checkFeaturePermission(requiredFeaturePermissions)) {
    return allowedFeature;
  }

  return alternateFeature;
};

export { checkSasPermission, checkFeaturePermission, userPermissionFeatureSwitch };
