import React, { useContext } from 'react';
import { appContext } from '../../../utils/context/userContext';
import { Methods } from '../../../utils/customHooks/apiTypes';
import { useFetch } from '../../../utils/customHooks/useFetch';
import SpinnerComponent from '../../Spinner/SpinnerComponent';

const ImageComponent = () => {
  const { icao } = useContext(appContext);
  const API_METHOD_GET: Methods = 'get';
  const PATH = `api/home/operatorimg?image_name=${icao}`;
  const [response, error] = useFetch(PATH, API_METHOD_GET);

  const renderImage = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {response?.message
          ? (
            <img
              className="operator-image"
              src={`data:image/png;base64, ${response.message}`}
              alt={icao}
              width="52"
              height="32"
            />
          )
          : <span className="operator-image">{icao}</span>}
      </>
    );
  };
  return (
    <div className="image-component">
      {(response || error) ? renderImage()
        : <SpinnerComponent label="" size="small" /> }
    </div>
  );
};

export default ImageComponent;
