/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from '@airbus/components-react';
import { isEqual } from 'lodash';

import BannerComponent from '../../Shared/BannerComponent/BannerComponent';
import GenericTable from '../../Shared/GenericTable/GenericTable';
import { RawTaskReportCols, noFilterSize } from '../../../__mocks__/UnscheduledEvents/TaskReportsTable';
import { getTypeCastedToStringRawTaskReport } from '../../../models/taskReportModel/taskReportUtils';
import {
  fetchRawTaskReports, fetchRawTaskReportsColumnSearch, fetchRawTaskReportsSyncStatus, startRawTaskReportsSync,
} from '../../../models/taskReportModel/taskreportAsyncThunks';
import {
  updateRawTaskReportfilter, updateRawTaskReportfilterValue, updateRawTaskReportfilterGroup, deleteRawTaskReportfilterGroup, updateTaskReportPerformanceTuning, clearRawTaskReportfilterGroup,
} from '../../../models/taskReportModel/taskReportSlice';
import { getTableColumns, generateHidableColumns } from '../../MpdTaskTable/mpdTableStructureUtils';
import { generateTableColumns, TableColumnState } from '../TRAnalysisUtils';
import { addBanner, removeBanner } from '../../../models/bannerModel/bannerSlice';
import { bannerLocationMap, LOADING_RAW_DATA_TABLE_DISCLAIMER } from '../../../models/bannerModel/constants';
import { LOCALES } from '../../../assets/locale';
import Crown from '../../Shared/Crown/Crown';

import { RootState, store } from '../../../store/store';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { DEFAULT_TABLE_DATA } from '../constants';
import NoData from '../../Shared/Charts/NoData';

interface QueryParams {
  pageIndex: number;
  pageSize: number;
  sortBy: [];
}

interface TATableProps {
  operator: string;
}

export const updateTaskReportColumnValue = (value: string) => store.dispatch(updateRawTaskReportfilterValue(value));

export const deleteFromTaskReportFilterGroup = (deletePair: searchPair[]) => store.dispatch(deleteRawTaskReportfilterGroup(deletePair));

const TATables = (props: TATableProps): JSX.Element => {
  const { operator } = props;
  const { filterId, taskNumber } = useParams();
  const dispatch = useAppDispatch();
  const [selectedTaskTableTab] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_TABLE_DATA.offset);
  const [limit, setLimit] = useState(DEFAULT_TABLE_DATA.limit);
  const { performance, rawTaskReports, rawTaskReportsSync } = useAppSelector((state: RootState) => state.task);
  const [isGenericTableLoading, setIsGenericTableLoading] = useState(true);
  const [isBannerDownloading, setIsBannerDownloading] = useState(false);
  const [isStatusFailed, setStatusFailed] = useState(false);

  const rawTaskReportsData = useMemo(() => getTypeCastedToStringRawTaskReport(rawTaskReports.data), [rawTaskReports.data]);

  const onPaginationChange = (params: QueryParams) => {
    const newOffset = Math.round(params.pageIndex * params.pageSize);
    setOffset(newOffset);
    setLimit(params.pageSize);
  };

  useEffect(() => {
    // clear already selected filters when component unmounts
    return () => {
      dispatch(clearRawTaskReportfilterGroup());
    };
  }, []);

  useEffect(() => {
    const { filterId: prevFilterId, taskNumber: prevTaskNumber, table } = performance;
    const { limit: prevLimit, offset: prevOffset, filterGroup: prevFilterGroup } = table;
    /* fetch raw task reports data only if filter Id, task number,limit, offset and filter group changes */
    if (prevFilterId !== filterId || prevTaskNumber !== taskNumber || prevLimit !== limit || prevOffset !== offset || !isEqual(prevFilterGroup, rawTaskReports.filterGroup)) {
      dispatch(updateTaskReportPerformanceTuning({ filterId, taskNumber, table: { limit, offset, filterGroup: rawTaskReports.filterGroup } }));
    }
  }, [filterId, taskNumber, operator, limit, offset, rawTaskReports.filterGroup]);

  useEffect(() => {
    /* Start Raw Task Report Sync */
    dispatch(startRawTaskReportsSync(filterId, taskNumber)());
  }, [filterId, taskNumber]);

  useEffect(() => {
    /* fetch raw task reports data:
    If the status is IN_PROGRESS, run every 5 seconds until the status is IMPORTED
    then retrieve the data when ready */
    let interval: ReturnType<typeof setInterval>;
    if (rawTaskReportsSync.status === 'IMPORTED') {
      dispatch(fetchRawTaskReports({
        taskNumber, filterId, limit, offset, searchColumn: rawTaskReports.filterGroup, operator,
      })());
    } else {
      interval = setInterval(() => {
        if (rawTaskReportsSync.status === 'IN_PROGRESS') {
          dispatch(fetchRawTaskReportsSyncStatus(filterId, taskNumber)());
        } else {
          setStatusFailed(true);
          clearInterval(interval);
        }
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [rawTaskReportsSync.status]);

  useEffect(() => {
    if (rawTaskReportsSync.status === 'IMPORTED') {
      dispatch(fetchRawTaskReports({
        taskNumber, filterId, limit, offset, searchColumn: rawTaskReports.filterGroup, operator,
      })());
    }
  }, [offset, rawTaskReports.filterGroup, limit]);

  useEffect(() => {
    if (rawTaskReportsSync.status === 'IN_PROGRESS') {
      setIsGenericTableLoading(true);
      if (!isBannerDownloading) {
        dispatch(addBanner(LOADING_RAW_DATA_TABLE_DISCLAIMER));
        setIsBannerDownloading(true);
      }
    } else {
      setIsGenericTableLoading(rawTaskReports.loading);
      if (!rawTaskReports.loading && !rawTaskReportsSync.loading) {
        dispatch(removeBanner(LOADING_RAW_DATA_TABLE_DISCLAIMER));
        setIsBannerDownloading(false);
      }
    }
  }, [rawTaskReports, rawTaskReportsSync]);

  const updateTaskReportColumnName = (columnName: string) => dispatch(updateRawTaskReportfilter(columnName));

  const updateTaskReportFilterGroup = () => dispatch(updateRawTaskReportfilterGroup([rawTaskReports?.searchPairData]));

  const fetchTaskReportFilterValues = (userInput: string) => dispatch(
    fetchRawTaskReportsColumnSearch({
      filterId,
      taskNumber,
      searchColumn: {
        columnName: rawTaskReports?.searchPairData?.columnName,
        columnInput: userInput,
      },
    })(),
  );

  const displayComponent = () => {
    if (isStatusFailed) {
      return (
        <div style={{ backgroundColor: '#FFFFFF' }}>
          <NoData type="error" message="Error occured. Please try after sometime. " customClassName="api-fetching-error" />
        </div>
      );
    }
    if (!rawTaskReportsData.length && !isBannerDownloading && !rawTaskReports.loading) {
      return (
        <div style={{ backgroundColor: '#FFFFFF' }}>
          <NoData message="No data found." customClassName="no-data" />
        </div>
      );
    }
    return (
      <GenericTable
        tableCols={RawTaskReportCols}
        tableData={rawTaskReportsData}
        totalDataCount={rawTaskReports.totalCount}
        onPaginationChangeCallback={onPaginationChange}
        Loading={isGenericTableLoading}
        updateColumnFilter={updateTaskReportColumnName}
        updateColumnFilterValue={updateTaskReportColumnValue}
        updateFilterGroup={updateTaskReportFilterGroup}
        deleteFromFilterGroup={deleteFromTaskReportFilterGroup}
        fetchColumnFilterValues={fetchTaskReportFilterValues}
        searchPair={rawTaskReports.searchPairData}
        columnFilter={rawTaskReports.searchOptions}
        filterGroup={rawTaskReports.filterGroup}
        generateTableColumns={generateTableColumns}
        tableColumnState={TableColumnState}
        getTableColumns={getTableColumns}
        generateHidableColumns={generateHidableColumns}
        isDataDynamic
        noSearchKeySizeLimitColumns={noFilterSize}
        filterChipPositionDetached
      />
    );
  };

  return (
    <div className="task-report-table">
      <Tabs
        style={{ margin: '15px 0 0 0' }}
        aria-label="task_report_table"
        value={selectedTaskTableTab}
      >
        <Tab>{LOCALES.Raw_Task_Report}</Tab>
        <Tab icon={<Crown />} disabled>{LOCALES.Enrich_Task_Report}</Tab>
      </Tabs>
      <div style={{ paddingTop: '0.5rem' }}>
        <BannerComponent location={bannerLocationMap.TA_TABLES} />
        <br />
      </div>
      {/* Hiding the tables on tab change instead of removing from dom for preventing additional api call */}
      {/* <div style={{ display: selectedTaskTableTab ? 'none' : 'block' }}> */}
      <div style={{ display: 'block' }}>
        { displayComponent() }
      </div>
    </div>
  );
};

export default TATables;
