import { createSlice } from '@reduxjs/toolkit';
import {
  createRepositoryTaskList,
  createRepoExportTask,
  createMpdRepositoryFilter,
  createRepoDeltaTableView,
  createViewGeneralInfoData,
  createRepoDeltaTableViewFilter,
  createPreviousRevisionData,
} from './mpdRepositoryAsyncThunk';
import {
  createRepositoryTaskListDataFullfilledReducer, createRepositoryTaskListDataPendingReducer,
  createMpdEmtlTableExportRejectedReducer, createRepositoryExportTaskFullfilledReducer,
  updateEmtlExportTaskReducer, createRepositoryExportTaskRejectedReducer,
  clearAllEmtlExportTaskBannerReducer, updateEmtlPageNumberReducer, updateEmtlRowsPerPageReducer,
  addSelectedFileReducer, updateSelectedFileReducer, addFilterOptionsFullfilled,
  updateRepositoryFilterReducer, deleteRepositoryTaskListDataReducer,
  updateRepositoryTabReducer, createRepositoryDeltaTableViewFullfilledReducer,
  createRepositoryDeltaTableViewRejectedReducer, setButtonDisabledReducer,
  createRepositoryDeltaTableViewFilterFullfilledReducer, clearAllRepoDeltaTableViewReducer,
  setFilterPanelOpenReducer, clearAllRepoDeltaTableViewDataReducer,
  updateGeneralInfoCardDataFulfilled, updateGeneralInfoCardDataRejected,
  clearGeneralInfoCardDataReducer, checkPreviousRevisionExistFulfilled,
  setPrevRevExportInProgressReducer, resetPreviousRevisionBannerReducer,
} from './mpdRepositoryReducers';
import { issueDataInitialState, establishedFromDataInitialState } from '../../__mocks__/MpdRepositoryDeltaView/mpdRepositoryDeltaViewGeneralInfo';

export const initialState: repositoryTaskListState = {
  RepositoryTaskListDataType: [],
  RepositoryEMTLTabType: 'EMTL',
  RepositoryTaskListRowCountType: 0,
  RepositoryTaskListPageSizeType: 0,
  RepositoryTaskListPageNumberType: 1,
  RepositoryTaskListCurPageNumber: 1,
  RepositoryTaskListRowsPerPage: 10,
  RepositoryTaskListLoading: true,
  error: '',
  RepositoryExportFileDownloadURL: '',
  RepositoryCompareFileDownloadURL: '',
  RespositoryExportFileDownloadError: false,
  RepositorySelectedFileKeys: [],
  RepositorySelectedFilesList: [],
  RepositoryFilterGroup: [],
  RepositorySelectedColumnType: 'issueNumber',
  RepositoryFilterOptionsType: [],
  RepositoryFilterSelectedOptionType: '',
  RepositoryButtonDisabled: '',
  RepositoryDeltaViewData: {
    filter_data: [],
    headers: [],
    records: [],
    totalHits: 0,
  },
  RepositoryDeltaViewFilterData: {
    column_name: '',
    column_value: [],
  },
  RepositoryDeltaViewFilterDataSanitized: {
    column_name: '',
    column_value: [],
  },
  RepositoryDeltaViewDataLoading: true,
  RepositoryDeltaViewDataError: false,
  RepositoryFilterPanalOpen: true,
  RepositoryDeltaGeneralInfoData: {
    reference_file: '',
    input_file: '',
    reference_file_general_info: {
      mpdRevision: 0,
      issueDate: '',
      revisionDate: '',
      issueNumber: '',
    },
    input_file_general_info: {
      mpdRevision: 0,
      issueDate: '',
      revisionDate: '',
      issueNumber: '',
    },
    ac_msn_diff: { headers: [], data: [] },
  },
  RepositoryDeltaViewCardIssueData: issueDataInitialState,
  RepositoryDeltaViewCardEstablishedData: establishedFromDataInitialState,
  RepositoryPreviousRevision: {
    isLatest: false,
    isRevisionExist: true,
    prevRevExportInProgress: false,
  },
};

const repositoryTaskListSlice = createSlice({
  name: 'repositoryTaskList',
  initialState,
  reducers: {
    updateEmtlExportTask: updateEmtlExportTaskReducer,
    clearAllEmtlExportTaskBanner: clearAllEmtlExportTaskBannerReducer,
    updateEmtlPageNumber: updateEmtlPageNumberReducer,
    updateEmtlRowsPerPage: updateEmtlRowsPerPageReducer,
    addSelectedFile: addSelectedFileReducer,
    updateSelectedFile: updateSelectedFileReducer,
    deleteRepositoryTaskListData: deleteRepositoryTaskListDataReducer,
    updateRepositoryFilter: updateRepositoryFilterReducer,
    updateFilterOptions: addFilterOptionsFullfilled,
    updateRepositoryTab: updateRepositoryTabReducer,
    setButtonDisabled: setButtonDisabledReducer,
    clearAllRepoDeltaTableView: clearAllRepoDeltaTableViewReducer,
    clearAllRepoDeltaTableViewData: clearAllRepoDeltaTableViewDataReducer,
    setFilterPanelOpen: setFilterPanelOpenReducer,
    clearGeneralInfoCardData: clearGeneralInfoCardDataReducer,
    setPrevRevExportInProgress: setPrevRevExportInProgressReducer,
    resetPreviousRevisionBanner: resetPreviousRevisionBannerReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(createRepositoryTaskList().pending, createRepositoryTaskListDataPendingReducer);
    builder.addCase(createRepositoryTaskList().fulfilled, createRepositoryTaskListDataFullfilledReducer);
    builder.addCase(createRepositoryTaskList().rejected, createMpdEmtlTableExportRejectedReducer);
    builder.addCase(createRepoExportTask().fulfilled, createRepositoryExportTaskFullfilledReducer);
    builder.addCase(createRepoExportTask().rejected, createRepositoryExportTaskRejectedReducer);
    builder.addCase(createMpdRepositoryFilter().fulfilled, addFilterOptionsFullfilled);
    builder.addCase(createRepoDeltaTableView().fulfilled, createRepositoryDeltaTableViewFullfilledReducer);
    builder.addCase(createRepoDeltaTableView().rejected, createRepositoryDeltaTableViewRejectedReducer);
    builder.addCase(createRepoDeltaTableViewFilter().fulfilled, createRepositoryDeltaTableViewFilterFullfilledReducer);
    builder.addCase(createViewGeneralInfoData().fulfilled, updateGeneralInfoCardDataFulfilled);
    builder.addCase(createViewGeneralInfoData().rejected, updateGeneralInfoCardDataRejected);
    builder.addCase(createPreviousRevisionData().fulfilled, checkPreviousRevisionExistFulfilled);
    builder.addCase(createPreviousRevisionData().pending, resetPreviousRevisionBannerReducer);
  },
});

export const {
  updateEmtlExportTask,
  clearAllEmtlExportTaskBanner,
  updateEmtlPageNumber,
  updateEmtlRowsPerPage,
  addSelectedFile,
  updateSelectedFile,
  deleteRepositoryTaskListData,
  updateRepositoryFilter,
  updateFilterOptions,
  updateRepositoryTab,
  setButtonDisabled,
  clearAllRepoDeltaTableView,
  clearAllRepoDeltaTableViewData,
  setFilterPanelOpen,
  clearGeneralInfoCardData,
  setPrevRevExportInProgress,
  resetPreviousRevisionBanner,
} = repositoryTaskListSlice.actions;
export default repositoryTaskListSlice;
