import { sasPermissions } from '../userPermissionUtils/userPermissionSas';

interface PermissionMapperType {
    [key: string]: string[];
}

const PERMISSION_MAPPER: PermissionMapperType = {
  dashboard: Object.values(sasPermissions.Dashboard) as string[],
  ProgramAnalysis: Object.values(sasPermissions.ProgramAnalysis) as string[],
  ProgramCustomization: Object.values(sasPermissions.ProgramCustomization) as string[],
  ProgramCustomizationRepository: Object.values(sasPermissions.ProgramCustomizationRepository) as string[],
};

export default PERMISSION_MAPPER;
