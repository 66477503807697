import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Container } from '@airbus/components-react';
import { getChartProps } from '../../../utils/chartUtils/chartUtils';
import { getChartComponent } from '../../../utils/chartUtils/chart.component';
import NoData from './NoData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
);

function Chart(props: chartPropsType) {
  const { chartData, apiStatus } = props;
  const {
    chartDataset, labelKey, chartTitle, type, details, height,
  } = chartData;
  const RenderChart = getChartComponent(type);
  const chartProps = getChartProps(chartDataset, labelKey, type, chartTitle, details);
  const { labels, datasets, options } = chartProps;
  const data = {
    labels,
    datasets,
  };

  const isDataAvailable = datasets[0]?.data?.length;

  const displayComponent = () => {
    if (apiStatus === 'failed') {
      return <NoData type="error" message="Error occured. Please try after sometime. " customClassName="api-fetching-error" />;
    }

    if (isDataAvailable) {
      return <RenderChart height={height} data={data} options={options} />;
    }
    return <NoData message="No data found." customClassName="no-data" />;
  };

  return (
    <Container>
      { displayComponent() }
    </Container>
  );
}

export default Chart;
