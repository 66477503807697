import { createSlice } from '@reduxjs/toolkit';
import { createMpdFilter } from './mpdAsyncThunk';
import { MPD_DRAWER_SLICE } from './constants';
import {
  createMpdFilterPending,
  createMpdFilterFulfilled,
  updateAcSeriesReducer,
  updateMsnRnoReducer,
  createMpdFilterRejected,
  updateEffectiveMaintenanceTask,
  updateMsnRnoSearchReducer,
} from './mpdDrawerReducers';

export const initialState: mpdDrawerState = {
  AircraftSeriesType: [],
  AircraftMsnRnoType: [],
  AircraftMsnRnoSearchType: [],
  AircraftFilters: [],
  MsnAndRegFilters: [],
  loading: false,
  error: '',
  effectiveMainenance: '',
};

const mpdDrawerSlice = createSlice({
  name: MPD_DRAWER_SLICE,
  initialState,
  reducers: {
    // sync action creators
    updateAcSeries: updateAcSeriesReducer,
    updateEffectiveMaintenance: updateEffectiveMaintenanceTask,
    updateMsnRno: updateMsnRnoReducer,
    updateMsnRnoSearch: updateMsnRnoSearchReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(createMpdFilter().pending, createMpdFilterPending);
    builder.addCase(createMpdFilter().fulfilled, createMpdFilterFulfilled);
    builder.addCase(createMpdFilter().rejected, createMpdFilterRejected);
  },
});

export const {
  updateAcSeries, updateEffectiveMaintenance, updateMsnRno, updateMsnRnoSearch,
} = mpdDrawerSlice.actions;
export default mpdDrawerSlice;
