import React, { useEffect, useRef, useState } from 'react';
import {
  useLocation,
  useNavigate, useParams,
} from 'react-router-dom';
import {
  Container,
} from '@airbus/components-react';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { getBuild, getBuildMetadata, getPAFilters } from '../../models/programAnalysis/programAnalysisAsyncThunks';
import FilterDrawer from '../FilterDrawer/FilterDrawer';
import EmptyContent from '../Shared/EmptyContent/EmptyContent';
import PAOverview from './Overview/PAOverview';
import { datasetsMetadata, maintenanceTask } from '../../models/programAnalysis/programAnalysisTypes';
import TaskList from './TaskList/TaskList';
import { clearBuild, updateBuildInfoObject } from '../../models/programAnalysis/programAnalysisSlice';

import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import DialogComponent from '../Shared/DialogComponent/DialogComponent';
import { RootState } from '../../store/store';
import { fetchPABuildListData } from '../../models/PABuildList/PABuildListAsyncThunk';
import { clearPABuildList } from '../../models/PABuildList/PABuildListSlice';

import { allFiltersKeys } from '../FilterDrawer/constants';
import {
  BUILD_DATASETS,
  BUILD_ID, BUILD_STATUS, DATASET_STATUS, MAINTENANCE_PROGRAM, OPERATOR_ICAO_CODE, POSSIBLE_BUILD_STATUS,
} from '../../models/programAnalysis/constants';
import { ANALYSIS_BUILD_EXCEED_OKAY, ANALYSIS_BUILD_EXCEED_SUBMIT, ANALYSIS_BUILD_EXCEED_TITLE } from '../Shared/DialogComponent/Constants';
import { NEW_ARCH } from '../TaskReportAnalysis/constants';
import './PAnalysis.scss';
import { architecture } from '../../models/programAnalysisRepository/PARepositoryAsyncThunk';

/* eslint-disable react-hooks/exhaustive-deps */
const PAnalysis: React.FC = () => {
  const pollDurationInSeconds = 10;
  const maxPollAttempts = 30;
  const selectedArchitecture = secureLS.get('architecture');
  const pollAttemptsRef = useRef(0);
  const [isPollingEnabled, setIsPollingEnabled] = useState(selectedArchitecture === NEW_ARCH);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const timerIdRef = useRef<any>(null);
  const operator = secureLS.get('operator');
  const acProgram = secureLS.get('ac_program');
  const { performance } = useAppSelector((state: RootState) => state.programAnalysis);
  const {
    build: buildInfo, tasks: taskObj,
  } = useAppSelector((state: RootState) => state.programAnalysis);
  const {
    loading: repoLoading,
  } = useAppSelector((state: RootState) => state.PARepository);
  const {
    BuildIdList: buildListData, BuildIdListCount: buildListDataCount, loading: buildListLoading,
  } = useAppSelector((state: RootState) => state.PABuildList);
  const urlParams = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const prevId = useRef<string>('');
  const [open, setOpen] = useState(false);
  const limitExceedMessage = (
    <>
      <p>You have reached the max number of analysis. You need to remove at least one before submitting new analysis.</p>
      <br />
      <p>After the deletion, you can come back here to create the new analysis.</p>
    </>
  );
  const countReadyDatasets = () => {
    let importedDatasets = 0;
    let maxDatasets = 0;
    if (Object.keys(buildInfo[BUILD_DATASETS] as datasetsMetadata)?.length !== 0) {
      maxDatasets = Object.keys(buildInfo[BUILD_DATASETS] as datasetsMetadata)?.length;
      Object.values(buildInfo[BUILD_DATASETS] as datasetsMetadata)?.forEach((item) => {
        if (item[DATASET_STATUS] === 'IMPORTED') importedDatasets += 1;
      });
    }
    return [importedDatasets, maxDatasets];
  };

  const subtitles = ['Calculating operator sample finding rate...',
    'Calculating world wide finding rate...',
    'Calculating operator yield...',
    'Calculating fleet yield...',
    'Calculating cost..'];

  const getRandomInt = (max : number) => {
    const d = new Date();
    return (d.getSeconds() % max);
  };

  /*
    Simple function to control what is displayed on the screen.
   */
  const switchMainScreen = (): JSX.Element | undefined => {
    if ((taskObj.fetchInfo.loading || buildInfo.fetchInfo.loading) && selectedArchitecture !== NEW_ARCH) {
      return <EmptyContent title="Loading data" subtitle="Please wait a few seconds" status="LOADING" />;
    }

    if (buildInfo.fetchInfo.loading && selectedArchitecture === NEW_ARCH && buildInfo[BUILD_STATUS] === '') {
      return (
        <EmptyContent
          title="Checking Build Status"
          subtitle=""
          status={POSSIBLE_BUILD_STATUS.INIT}
        />
      );
    }

    switch (buildInfo[BUILD_STATUS]) {
      case POSSIBLE_BUILD_STATUS.READY:
        if (selectedArchitecture !== NEW_ARCH) {
          return <EmptyContent title="No data" subtitle="These filters do not contain any associated MPD" status={POSSIBLE_BUILD_STATUS.READY} />;
        }
        return (
          <EmptyContent
            title="Loading Computation..."
            subtitle=""
            status={POSSIBLE_BUILD_STATUS.INIT}
          />
        );
      case POSSIBLE_BUILD_STATUS.INIT:
      case POSSIBLE_BUILD_STATUS.IN_PROGRESS: {
        if (selectedArchitecture === NEW_ARCH) {
          return (
            <EmptyContent
              title="Build In Progress"
              subtitle={subtitles[getRandomInt(5)]}
              status={POSSIBLE_BUILD_STATUS.IN_PROGRESS}
              progress={[buildInfo.progress_percentage || 0, 100]}
            />
          );
        }
        const infoDatasets = countReadyDatasets();
        return (
          <EmptyContent
            title="Build in progress"
            subtitle={`${infoDatasets[0]}/${infoDatasets[1]} datasets imported, please come back later`}
            status={POSSIBLE_BUILD_STATUS.IN_PROGRESS}
            progress={infoDatasets}
          />
        );
      }
      case POSSIBLE_BUILD_STATUS.FAILED:
        return <EmptyContent title="Last build failed" subtitle="An error occurred with the latest build of data, please rebuild the filter" status={POSSIBLE_BUILD_STATUS.FAILED} />;
      case POSSIBLE_BUILD_STATUS.UNAUTHORIZED:
        return <EmptyContent title="Unauthorized access" subtitle="Either you don't have access to this build, or it doesn't exist" status={POSSIBLE_BUILD_STATUS.UNAUTHORIZED} />;
      case '':
        return <EmptyContent title="Access saved results" subtitle="You can access your saved results in repository" status="EMPTY" />;
      default:
        return <EmptyContent title="Build status not handled" subtitle="An error occurred, please contact the appropriate person" status="UNKNOWN" />;
    }
  };

  /*
    Simple function deciding if we display the Overview component or not. Was too long to put in the code directly.
    We check if :
    - We have data (array, and the array length > 0
    - The build ID in the URL corresponds to the one in the Redux store
   */
  const displayOverview = () => {
    return ((taskObj.data as maintenanceTask[]) && (taskObj.data as maintenanceTask[]).length) > 0 && !taskObj.fetchInfo.loading && buildInfo[BUILD_STATUS] === POSSIBLE_BUILD_STATUS.READY;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = () => {
    navigate('/repository', { state: '/program-analysis' });
  };
  const clearPABuildListCleanup = () => {
    dispatch(clearPABuildList());
  };

  const stopPolling = () => {
    clearInterval(timerIdRef.current);
    timerIdRef.current = null;
  };

  // this function is to avoid caching issue of GETBUILD api in task distribution page
  const handleBuildInfoConditions = () => {
    // If the build status is READY and there is no previous data
    if (buildInfo[BUILD_STATUS] === POSSIBLE_BUILD_STATUS.READY && buildInfo[BUILD_ID] !== prevId.current) {
      return true;
    }
    return false;
  };

  /*
    Loading data when mounting the component
    We change the ID of the build in Redux store, which will in turn trigger the below useEffect
    We also get the filters so that we can fill them when retrieve the build metadata below
    If data is already there, we don't re-fire request as we already have all we need
   */
  useEffect(() => {
    // dispatch(clearPARepoState());
    if (architecture() !== NEW_ARCH) {
      dispatch(fetchPABuildListData(operator, acProgram)());
    }
    if (displayOverview()) return clearPABuildListCleanup;
    dispatch(updateBuildInfoObject({ id: urlParams.id }));
    if (urlParams.id && urlParams.id !== '') {
      dispatch(getPAFilters({ [OPERATOR_ICAO_CODE]: secureLS.get('operator'), [MAINTENANCE_PROGRAM]: secureLS.get('ac_program'), select: allFiltersKeys })({ initial: true }));
    }
    return () => {
      clearPABuildListCleanup();
      stopPolling();
    };
  }, []);

  /*
    We get the build metadata if the ID exist and is not empty
    As per the above useEffect, we don't fire the request if the metadata is already there
   */
  useEffect(() => {
    if (displayOverview()) return;
    if (buildInfo[BUILD_ID] && buildInfo[BUILD_ID] !== '') {
      dispatch(getBuildMetadata(buildInfo[BUILD_ID])());
      if (!urlParams.id) {
        navigate(`${buildInfo[BUILD_ID]}`);
      } else {
        navigate(`../${buildInfo[BUILD_ID]}`, { replace: true });
      }
    }
  }, [buildInfo[BUILD_ID]]);

  /*
    We navigate to the right URL when the ID is updated, even when it is not READY.
    This way, the user can easily refresh the page without re-selecting the filters over and over
    If the build status is READY and there is no previous data, we fetch the data and clear the previous selected task if any
   */
  useEffect(() => {
    // const { filterId: prevFilterId } = performance;
    if (buildInfo[BUILD_ID] && buildInfo[BUILD_ID] !== '') {
      // handle BuildInfoCondition function return TRUE If the build status is READY and there is no previous data,
      // in NewArchitecture we are checking if filter is not same as previous filter then we hit Api to avoid Caching
      if ((selectedArchitecture === NEW_ARCH) ? (buildInfo[BUILD_ID] !== performance.prevBuildId && handleBuildInfoConditions()) : (handleBuildInfoConditions())) {
        dispatch(getBuild(buildInfo[BUILD_ID])());
        prevId.current = buildInfo[BUILD_ID];
      }
    }
  }, [buildInfo[BUILD_STATUS], buildInfo[BUILD_ID], urlParams.id]);

  useEffect(() => {
    if (buildInfo.buildLimitExceed) {
      setOpen(true);
      dispatch(clearBuild());
      return;
    }
    if (buildListData.length > 9 && !buildListLoading && !location.pathname.includes('program-analysis/')) {
      setOpen(true);
      dispatch(clearPABuildList());
    }
  }, [buildInfo.buildLimitExceed, buildListDataCount, repoLoading, buildInfo.id]);

  // precomp changes
  useEffect(() => {
    const pollingCallback = () => {
      // call build status api
      dispatch(getBuildMetadata(buildInfo[BUILD_ID])()).unwrap();

      if (buildInfo[BUILD_STATUS] === POSSIBLE_BUILD_STATUS.READY
        || pollAttemptsRef.current > maxPollAttempts) {
        setIsPollingEnabled(false);
      }
      pollAttemptsRef.current += 1;
    };

    const startPolling = () => {
      // Polling every 15 seconds
      timerIdRef.current = setInterval(pollingCallback, pollDurationInSeconds * 1000);
    };

    if ([POSSIBLE_BUILD_STATUS.INIT, POSSIBLE_BUILD_STATUS.IN_PROGRESS].includes(buildInfo[BUILD_STATUS] as string)
      && isPollingEnabled
      && selectedArchitecture === NEW_ARCH
    ) {
      // Dont start polling if an existing polling is already in progress
      if (!timerIdRef.current) {
        startPolling();
      }
    } else {
      stopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildInfo.status, buildInfo.id, isPollingEnabled]);

  return (
    <Container>
      <div className="row-program-analysis">
        <div className="col-drawer">
          <FilterDrawer />
        </div>
        <div className="main-content">
          {
            displayOverview() ? (
              <>
                <PAOverview />
                <TaskList />
              </>
            ) : switchMainScreen()
          }
        </div>
      </div>
      {open && (
        <DialogComponent
          message={limitExceedMessage}
          dialogTitle={ANALYSIS_BUILD_EXCEED_TITLE}
          primmaryButtonName={ANALYSIS_BUILD_EXCEED_SUBMIT}
          secondaryButtonName={ANALYSIS_BUILD_EXCEED_OKAY}
          open={open}
          onClose={handleClose}
          onSubmit={onSubmit}
          key={ANALYSIS_BUILD_EXCEED_TITLE}
        />
      )}
    </Container>
  );
};

export default PAnalysis;
