import React, { useContext, useState } from 'react';
import {
  Card, CardContent, CardHeader, IconButton, Modal, RangeSlider, Row, Toggle, Typography,
} from '@airbus/components-react';
import { Close, CropFree } from '@airbus/icons/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { maintenanceTask, taskListFilters } from '../../../models/programAnalysis/programAnalysisTypes';
import MaintTaskChart from '../../MaintTaskChart/MaintTaskChart';
import { updateTasksObject } from '../../../models/programAnalysis/programAnalysisSlice';
import {
  COST_AIRCRAFT_YEAR, NUMBER_OF_TASK_REPORT, OPERATOR_FINDING_RATE, OPERATOR_YIELD, OPPORTUNITY,
  SUFFICIENT_TASK_REPORT,
} from '../../../models/programAnalysis/constants';
import './PAOverview.scss';
import { txtThumbLabel } from './utils';
import { appContext } from '../../../utils/context/userContext';
import { featureSwitchConfig } from '../../../utils/FeatureToggleUtil/FeatureToggleUtil';

const PAOverview = () => {
  const taskList = useAppSelector((state) => state.programAnalysis.tasks.data as maintenanceTask[]);
  const taskObj: taskListFilters = useAppSelector((state) => state.programAnalysis.tasks);
  const dispatch = useAppDispatch();
  const defaultSliderValues = [0, 100];
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { userType } = useContext(appContext);

  const maxValue = Math.max(...taskList.map((item) => item.interval_fh as number));

  const dispatchFilters = (filters: taskListFilters) => {
    dispatch(updateTasksObject({ filters }));
  };

  const sampleFindingRateRangeValues = () => {
    if (taskObj.filters[OPERATOR_FINDING_RATE]) {
      return [taskObj.filters[OPERATOR_FINDING_RATE][0], taskObj.filters[OPERATOR_FINDING_RATE][1]];
    }
    return defaultSliderValues;
  };

  const operatorYieldValues = () => {
    if (taskObj.filters[OPERATOR_YIELD]) {
      return [taskObj.filters[OPERATOR_YIELD][0], taskObj.filters[OPERATOR_YIELD][1]];
    }
    return defaultSliderValues;
  };

  const dmcRangeValues = () => {
    if (taskObj.filters[COST_AIRCRAFT_YEAR]) {
      return [taskObj.filters[COST_AIRCRAFT_YEAR][0], taskObj.filters[COST_AIRCRAFT_YEAR][1]];
    }
    return defaultSliderValues;
  };
  // defalut sliders - DMS Range , OPSFR
  const defaultSlidersData = [
    {
      id: 1,
      title: 'DMC Range ( $ per A/C per year )',
      ticks: [0, 25, 50, 75, 100],
      ariaLabel: 'sliderdmc',
      step: 25,
      getTickLabel: (val: number) => `${val}%`,
      getThumbLabel: (_index: number, val: number) => txtThumbLabel(val),
      max: 100,
      min: 0,
      defaultValue: defaultSliderValues,
      value: dmcRangeValues(),
      onChange: (val: number[]) => dispatchFilters({ ...taskObj.filters, [COST_AIRCRAFT_YEAR]: val }),
    },
    {
      id: 2,
      title: 'Operator Sample Finding Rate ( % )',
      ticks: [0, 25, 50, 75, 100],
      ariaLabel: 'slider-interval',
      getTickLabel: (val: number) => `${val}%`,
      max: 100,
      min: 0,
      defaultValue: defaultSliderValues,
      value: sampleFindingRateRangeValues(),
      onChange: (val: unknown) => dispatchFilters({ ...taskObj.filters, [OPERATOR_FINDING_RATE]: val }),
    },
  ];
  // new sliders - DMS Range , OPSFR, Operator Yield
  const newSlidersData = [
    ...defaultSlidersData,
    {
      id: 3,
      title: 'Operator Yield ( % )',
      ticks: [0, 25, 50, 75, 100],
      ariaLabel: 'slider-op-yield-interval',
      getTickLabel: (val: number) => `${val}%`,
      max: 100,
      min: 0,
      defaultValue: defaultSliderValues,
      value: operatorYieldValues(),
      onChange: (val: unknown) => dispatchFilters({ ...taskObj.filters, [OPERATOR_YIELD]: val }),
    },
  ];
  // default toggles - Task Optimization Opportunities, Display only task with reported data
  const defaultTogglesData = [
    {
      id: 1,
      title: 'Task Optimization Opportunities',
      checked: taskObj.filters[OPPORTUNITY] || false,
      ariaLabel: 'toggle-opportunities',
      onChange: () => {
        dispatchFilters({ ...taskObj.filters, [OPPORTUNITY]: !taskObj.filters[OPPORTUNITY] });
      },
    },
    {
      id: 2,
      title: 'Display only task with reported data',
      checked: taskObj.filters[NUMBER_OF_TASK_REPORT] || false,
      ariaLabel: 'toggle-reported-data',
      onChange: () => {
        dispatchFilters({ ...taskObj.filters, [NUMBER_OF_TASK_REPORT]: !taskObj.filters[NUMBER_OF_TASK_REPORT] });
      },
    },
  ];
  // new toggles - Task Optimization Opportunities, Display only task with reported data, Sufficient Reported Data
  const newTogglesData = [
    ...defaultTogglesData,
    {
      id: 3,
      title: 'Sufficient Reported Data',
      checked: taskObj.filters[SUFFICIENT_TASK_REPORT] || false,
      ariaLabel: 'toggle-sufficient-data',
      onChange: () => {
        dispatchFilters({ ...taskObj.filters, [SUFFICIENT_TASK_REPORT]: !taskObj.filters[SUFFICIENT_TASK_REPORT] });
      },
    },
  ];

  const displaySliders = () => {
    const slidersData = featureSwitchConfig(
      { name: 'maintanence-task-list-new-columns', userType },
      newSlidersData,
      defaultSlidersData,
    );
    return slidersData.map((item, index) => {
      if (index === 1 && (taskList as maintenanceTask[]).length === 1) {
        return (
          <div className="sliders-mtt" key={item.id}>
            <p className="slider-title">{`${item.title}: ${Math.ceil(maxValue)} FH`}</p>
          </div>
        );
      }
      return (
        <div className="sliders-mtt" key={item.id}>
          <p className="slider-title">{item.title}</p>
          <RangeSlider key={item.id} ticks={item.ticks} aria-label={item.ariaLabel} getTickLabel={item.getTickLabel} getThumbLabel={item.getThumbLabel} max={item.max} min={item.min} value={item.value} defaultValue={item.defaultValue} onChange={item.onChange} step={item.step} />
        </div>
      );
    });
  };

  const displayToggles = () => {
    const togglesData = featureSwitchConfig(
      { name: 'maintanence-task-list-new-columns', userType },
      newTogglesData,
      defaultTogglesData,
    );
    return togglesData.map((item) => {
      return (
        <div className="toggle-container" key={item.id}>
          <p>{item.title}</p>
          <Toggle aria-label={item.ariaLabel} checked={item.checked} size="medium" onChange={item.onChange} />
          {' '}
        </div>
      );
    });
  };

  return (
    <div className="pa-overview-container">
      <Row>
        <p className="main-frame-title">Overview</p>
      </Row>
      <Row>
        <Card className="chart-container">
          <CardHeader
            title="Tasks distribution"
            action={(
              <IconButton variant="ghost" onClick={() => setIsModalOpened(true)} aria-label="zoom-chart-button">
                <CropFree aria-label="zoom button" />
              </IconButton>
            )}
          />
          <CardContent className="chart-container-content">
            <MaintTaskChart data={taskList as maintenanceTask[]} />
          </CardContent>
        </Card>
        <Card className="options-container">
          <CardHeader
            title="Filters"
          />
          <CardContent>
            <>
              {displayToggles()}
            </>
          </CardContent>
        </Card>
        <Card className="sliders-container">
          <CardHeader
            title="Sliders"
          />
          <CardContent>
            <>
              {displaySliders()}
            </>
          </CardContent>
        </Card>
      </Row>
      <Modal aria-label="modal-task-piechart" aria-labelledby="modal-title" aria-describedby="modal-description" open={isModalOpened} className="modal-display-style">
        <div className="modal-style-zoom">
          <div className="modal-header">
            <Typography variant="large">Tasks distribution</Typography>
            <IconButton variant="ghost" onClick={() => setIsModalOpened(false)} aria-label="zoom-out-button">
              <Close />
            </IconButton>
          </div>

          <div style={{ height: '75%', position: 'absolute', width: 'inherit' }}>
            <MaintTaskChart data={taskList as maintenanceTask[]} isZoomed />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PAOverview;
