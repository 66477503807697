import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Header as AppBar,
  Tabs,
  Tab,
  IconButton,
  Typography,
  Link,
  Badge,
} from '@airbus/components-react';
import { Notification, Help } from '@airbus/icons/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import headerTabs from '../../../__mocks__/AppHeader/headerTabs';
import './AppHeader.scss';
import { appContext } from '../../../utils/context/userContext';
import { featureSwitchConfig } from '../../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { UserguideHelperLinks } from '../../../utils/UserguideLinks/UserguideHelperLinks';
import NotificationComponent from '../../NotificationComponent/NotificationComponent';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { fetchNotificationData, updateNotificationsAsRead } from '../../../models/BellNotificationModel/BellNotificationAsyncThunk';
import { clearNotificationData } from '../../../models/BellNotificationModel/BellNotificationSlice';
import { RootState } from '../../../store/store';

const NOTIFICATION_LIMIT = 5;
const NOTIFICATION_OFFSET = 0;
interface dataProps {
  appName: string;
}

const AppHeader: React.FC<dataProps> = function AppHeader(props: dataProps) {
  const { appName } = props;
  const { userType } = useContext(appContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [givenName, setGivenName] = useState('');
  const [preferredName, setPrefferedName] = useState('');
  const [loading, setLoading] = useState(true);
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
  const [isNotificationEnable, setIsNotificationEnable] = useState(false);
  const { features } = useAppSelector((state: RootState) => state.featureToggle);
  const { notifications, unreadCount } = useAppSelector((state: RootState) => state.BellNotification);
  const dispatch = useAppDispatch();
  const bellIconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((amplifyUser) => {
        setGivenName(amplifyUser.attributes.given_name);
        setPrefferedName(amplifyUser.attributes.preferred_username || amplifyUser.attributes.family_name);
        setLoading(false);
      }).catch(() => {});
  }, []);
  useEffect(() => {
    const currNav = headerTabs.find((navItem) => location.pathname.includes(navItem.routeTo) && navItem.routeTo !== '/');
    setTabSelectedIndex(currNav ? currNav.id : 0);
  }, [location]);

  useEffect(() => {
    // Added 'features' in condition since this is global component and hence
    // its getting called before 'featureToggleList' API, and giving wrong result.
    if (userType && features) {
      const toggledBellNotification = featureSwitchConfig(
        { name: 'bellNotificationFeature', userType },
        () => dispatch(fetchNotificationData(NOTIFICATION_OFFSET, NOTIFICATION_LIMIT)()),
        () => null,
      );
      toggledBellNotification();
    }
  }, [dispatch, userType, features]);

  // feature toggle for internal & external users userguide url
  const renderHelpView = featureSwitchConfig(
    { name: 'userGuideHelpView', userType },
    () => (
      <Link className="help-button-link" href={UserguideHelperLinks(userType)} target="_blank">
        <Help />
      </Link>
    ),
    () => <Help />,
  );

  const handleNotificationsAsRead = () => {
    if (unreadCount) {
      const notificationsToMarkRead = notifications.filter((notification) => notification.unread).map((notification) => notification.notificationId);
      dispatch(updateNotificationsAsRead(notificationsToMarkRead)())
        .unwrap().then((results: { message: string }) => {
          if (results) {
            dispatch(clearNotificationData());
            dispatch(fetchNotificationData(NOTIFICATION_OFFSET, NOTIFICATION_LIMIT)());
          }
        }).catch(() => { });
    }
  };

  // Checks if a click event occurred outside of the referenced element
  const isClickOutsideBell = (target: EventTarget | null): boolean => {
    return bellIconRef.current ? !bellIconRef.current.contains(target as Node) : false;
  };

  const handleBellIconToggle = () => {
    setIsNotificationEnable(!isNotificationEnable);
    if (isNotificationEnable) {
      handleNotificationsAsRead();
    }
  };

  const handleNotificationClose = () => {
    setIsNotificationEnable(false);
    handleNotificationsAsRead();
  };

  const calculatePopupRightPosition = () => {
    const rect = bellIconRef?.current?.getBoundingClientRect();
    return window.innerWidth - (rect?.right || 0);
  };

  const notificationWithBadge = (
    <div ref={bellIconRef}>
      <IconButton variant="ghost" aria-label="Notifications-Count" onClick={handleBellIconToggle}>
        <Notification />
        {(unreadCount > 0 && !isNotificationEnable) && <Badge count={unreadCount} variant="error" className="show-badge" />}
      </IconButton>
    </div>
  );

  const notificationWithoutBadge = (
    <IconButton variant="ghost" aria-label="Notifications">
      <Notification />
    </IconButton>
  );
  // feature flipping for enable bell Icon
  const renderNotificationBellIcon = featureSwitchConfig(
    { name: 'bellNotificationFeature', userType },
    notificationWithBadge,
    notificationWithoutBadge,
  );

  const renderTabs = () => (
    <Tabs value={tabSelectedIndex}>
      {headerTabs.map((headerTab) => (
        <Tab
          key={headerTab.id}
          value={headerTab.id}
          onClick={() => navigate(headerTab.routeTo, {
            state: location.pathname,
          })}
          aria-label="HeaderNav"
          disabled={headerTab.disabled}
        >
          {headerTab.title}
        </Tab>
      ))}
    </Tabs>
  );
  return (
    <div className="exclude-print header-cls app-header-cls">
      <AppBar appName={appName}>
        {location.pathname !== '/' && renderTabs()}
        {renderNotificationBellIcon}
        <IconButton variant="ghost" aria-label="Help">
          {renderHelpView()}
        </IconButton>
        {loading ? (
          <SpinnerComponent size="small" label="" />
        ) : (
          <Tabs>
            <Tab>{`${givenName} ${preferredName}`}</Tab>
          </Tabs>
        )}
        <IconButton
          className="btn-font-small"
          name="LogoutButton"
          onClick={() => navigate('/signout')}
        >
          <Typography variant="small">Logout</Typography>
        </IconButton>
      </AppBar>
      { isNotificationEnable && (
        <div className="notification-container" style={{ right: calculatePopupRightPosition() }}>
          <NotificationComponent handleNotificationClose={handleNotificationClose} isClickOutsideBell={isClickOutsideBell} />
        </div>
      )}
    </div>
  );
};

export default AppHeader;
