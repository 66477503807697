import { initialState } from './taskReportSlice';
import { generateTaskReportMonthYearFormat, sortTaskReportByYearData } from './taskReportUtils';

export const fetchTaskInfoPending = (state: TaskReportState) => ({
  ...state,
  loading: true,
  status: 'idle',
});

export const fetchTaskInfoFulfilled = (state: TaskReportState, action: FetchTaskInfoFulfilledAction) => ({
  ...state,
  loading: false,
  taskInfo: action.payload.data[0],
  status: 'succeeded',
  httpStatus: 200,
});

export const fetchTaskInfoRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  loading: false,
  error: action.payload?.error,
  httpStatus: action.payload?.httpStatus,
  status: 'failed',
});

export const fetchTaskReportAnalysisPending = (state: TaskReportState) => ({
  ...state,
  taskReportAnalysis: {
    ...state.taskReportAnalysis,
    loading: true,
    status: 'idle',
  },
});

export const fetchTaskReportAnalysisFulfilled = (state: TaskReportState, action: FetchTaskReportAnalysisFulfilledAction) => ({
  ...state,
  taskReportAnalysis: {
    ...action.payload.data[0],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskReportAnalysisRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskReportAnalysis: {
    ...state.taskReportAnalysis,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

export const fetchTaskInfoFleetLevelPending = (state: TaskReportState) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    loading: true,
    status: 'idle',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoFleetLevelFulfilled = (state: TaskReportState, { payload: { data } } : FetchTaskInfoFleetLevelFulfilledAction) => ({
  ...state,
  taskInfoFleetLevel: {
    all_climate_regions: data.length ? data[0].all_climate_regions : [],
    climate_fleet_finding_rate: data.length ? data[0].climate_fleet_finding_rate : [],
    fleet_finding_list: data.length ? data[0].fleet_finding_list : [],
    fleet_finding_avg_count: data.length ? data[0].fleet_finding_avg_count : [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskInfoFleetLevelRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

// next SIX only for NewArchitecture
export const fetchTaskInfoFleetLevelGeoRegionChartPending = (state: TaskReportState) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    loading: true,
    status: 'idle',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoFleetLevelGeoRegionChartFulfilled = (state: TaskReportState, { payload: { data } } : FetchTaskInfoFleetLevelGEOFulfilledAction) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    all_climate_regions: data?.all_climate_regions || [],
    climate_fleet_finding_rate: data?.climate_fleet_finding_rate || [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskInfoFleetLevelGeoRegionChartRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

export const fetchTaskInfoFleetLevelFindingChartPending = (state: TaskReportState) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    loading: true,
    status: 'idle',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoFleetLevelFindingChartFulfilled = (state: TaskReportState, { payload: { data } } : FetchTaskInfoFleetLevelGEOFulfilledAction) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    fleet_finding_list: data?.fleet_finding_list || [],
    fleet_finding_avg_count: data?.fleet_finding_avg_count || [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskInfoFleetLevelFindingChartRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoFleetLevel: {
    ...state.taskInfoFleetLevel,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});
// operator level finding vs fing chart
export const fetchTaskInfoOperatorLevelFindingChartPending = (state: TaskReportState) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: true,
    status: 'idle',
  },
});
// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoOperatorLevelFindingChartFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoperatorLevelYearlyFulfilledAction) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    operator_finding_list: data?.operator_finding_list || [],
    operator_finding_avg_count: data?.operator_finding_avg_count || [],
  },
});

export const fetchTaskInfoOperatorLevelFindingChartRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});
// finding rate by msn
export const fetchTaskInfoOperatorLevelFindingRateByMsnPending = (state: TaskReportState) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: true,
    status: 'idle',
  },
});
// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoOperatorLevelFindingRateByMsnFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoperatorLevelYearlyFulfilledAction) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    id_aircraft: data?.id_aircraft || [],
    all_finding_per_id_aircraft_count: data?.all_finding_per_id_aircraft_count || [],
    nil_finding_per_id_aircraft_count: data?.nil_finding_per_id_aircraft_count || [],
    finding_rate_per_id_aircraft_count: data?.finding_rate_per_id_aircraft_count || [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const fetchTaskInfoOperatorLevelFindingRateByMsnRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});
// operator level finding yearly chart
export const fetchTaskInfoOperatorLevelReportReceivedYearlyPending = (state: TaskReportState) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: true,
    status: 'idle',
  },
});
// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoOperatorLevelReportReceivedYearlyFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoperatorLevelYearlyFulfilledAction) => ({

  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    operator_task_year: generateTaskReportMonthYearFormat(data?.operator_task_year || [], data?.operator_task_year_count || [], data?.operator_task_month || []),
    operator_task_year_count: data?.operator_task_year_count || [],
    /* operator_task_month is set to empty array [] by default as the data is appended with operator_task_year */
    operator_task_month: data.operator_task_month || [],
  },
});

export const fetchTaskInfoOperatorLevelReportReceivedYearlyRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

export const fetchTaskInfoOperatorLevelPending = (state: TaskReportState) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: true,
    status: 'idle',
  },
});

export const fetchTaskInfoFleetLevelCSMFilteredPending = (state: TaskReportState) => ({
  ...state,
  taskInfoFleetLevelCSMFiltered: {
    ...state.taskInfoFleetLevelCSMFiltered,
    loading: true,
    status: 'idle',
  },
});
// reducer for task report analysis operator card new architecture
export const fetchTaskReportOperatorCardFulfilled = (state: TaskReportState, { payload: { data } } : FetchTaskReportOperatorCardFulfilledAction) => ({
  ...state,
  taskInfoFleetLevelCSMFiltered: {
    ...state.taskInfoFleetLevelCSMFiltered,
    operator_finding_rate: data.length ? data[0].operator_finding_rate : '',
    loading: false,
    status: '',
    httpStatus: 200,
  },
  taskReportAnalysis: {
    ...state.taskReportAnalysis,
    total_tr: data.length ? data[0].total_tr : '',
    total_ac_reported: data.length ? data[0].total_ac_reported : '',
    sufficient_reported_data_for_optimization: data.length ? data[0].sufficient_reported_data_for_optimization : '',
    sufficent_reported_bar: data.length ? data[0].sufficent_reported_bar : '',
    sufficient_task_report: data.length ? data[0].sufficient_task_report : '',
    avg_yield: data.length ? data[0].avg_yield : '',
    ffr: data.length ? data[0].ffr : '',
    me: data.length ? data[0].me : '',
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});
export const fetchTaskReportOperatorCardRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoFleetLevelCSMFiltered: {
    ...state.taskInfoFleetLevelCSMFiltered,
    loading: false,
    status: 'failed',
    httpStatus: action.payload?.httpStatus,
  },
  taskReportAnalysis: {
    ...state.taskReportAnalysis,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

export const fetchTaskReportOperatorCardPending = (state: TaskReportState) => ({
  ...state,
  taskInfoFleetLevelCSMFiltered: {
    ...state.taskInfoFleetLevelCSMFiltered,
    loading: true,
    status: 'idle',
  },
  taskReportAnalysis: {
    ...state.taskReportAnalysis,
    loading: true,
    status: 'idle',
  },
});
// reducers for World Wide Fleet card new architecture
export const fetchTaskReportWorldWideCardFulfilled = (state: TaskReportState, { payload: { data } } : FetchTaskReportOperatorCardFulfilledAction) => ({
  ...state,
  taskInfoFleetCard: {
    ...state.taskInfoFleetCard,
    world_wide_finding_rate: data.length ? data[0].world_wide_finding_rate : '',
    loading: false,
    status: '',
    httpStatus: 200,
  },
});
export const fetchTaskReportWorldWideCardRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoFleetCard: {
    ...state.taskInfoFleetCard,
    loading: false,
    status: 'failed',
    httpStatus: action.payload?.httpStatus,
  },
});

export const fetchTaskReportWorldWideFleetCardPending = (state: TaskReportState) => ({
  ...state,
  taskInfoFleetCard: {
    ...state.taskInfoFleetCard,
    loading: true,
    status: 'idle',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoFleetLevelCSMFilteredFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoFleetLevelCSMFilteredFulfilledAction) => ({
  ...state,
  taskInfoFleetLevelCSMFiltered: {
    ...state.taskInfoFleetLevelCSMFiltered,
    operator_finding_rate: data.length ? data[0].operator_finding_rate : '',
    world_wide_finding_rate: data.length ? data[0].world_wide_finding_rate : '',
    loading: false,
    status: '',
    httpStatus: 200,
  },
});

export const fetchTaskInfoFleetLevelCSMFilteredRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoFleetLevelCSMFiltered: {
    ...state.taskInfoFleetLevelCSMFiltered,
    loading: false,
    status: 'failed',
    httpStatus: action.payload?.httpStatus,
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
export const fetchTaskInfoOperatorLevelFulfilled = (state: TaskReportState, { payload: { data } }: FetchTaskInfoOperatorLevelFulfilledAction) => {
  let sortedYear: Array<number | string> = [];
  let sortedYearCount: Array<number | string> = [];
  if (data.length) {
    [sortedYear, sortedYearCount] = sortTaskReportByYearData(
      data[0].operator_task_year,
      data[0].operator_task_year_count,
      data[0].operator_task_month,
    );
  }

  return {
    ...state,
    taskInfoOperatorLevel: {
      operator_task_year: sortedYear,
      operator_task_year_count: sortedYearCount,
      /* operator_task_month is set to empty array [] by default as the data is appended with operator_task_year */
      operator_task_month: [],
      operator_finding_list: data.length ? data[0].operator_finding_list : [],
      operator_finding_avg_count: data.length ? data[0].operator_finding_avg_count : [],
      id_aircraft: data.length ? data[0].id_aircraft : [],
      all_finding_per_id_aircraft_count: data.length ? data[0].all_finding_per_id_aircraft_count : [],
      nil_finding_per_id_aircraft_count: data.length ? data[0].nil_finding_per_id_aircraft_count : [],
      finding_rate_per_id_aircraft_count: data.length ? data[0].finding_rate_per_id_aircraft_count : [],
      ffr: data.length ? data[0].ffr : '',
      me: data.length ? data[0].me : '',
      loading: false,
      error: '',
      httpStatus: 200,
      status: 'succeeded',
    },
  };
};

export const fetchTaskInfoOperatorLevelRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskInfoOperatorLevel: {
    ...state.taskInfoOperatorLevel,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

/**
 * Set taskReportByActualInterval loading to true when api is inprogress
 * @param state current state
 * @return updated state ( loading => true )
 */
export const fetchTaskReportsByActualIntervalPending = (state: TaskReportState) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    loading: true,
    status: 'idle',
  },
});

/**
 * Set taskReportByActualInterval data fetched from api and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( data => payload.data, loading => false )
 */
export const fetchTaskReportsByActualIntervalFulfilled = (state: TaskReportState, { payload }: FetchTaskReportsByActualIntervalFulfilledAction) => ({
  ...state,
  taskReportByActualInterval: {
    data: payload?.data.length ? payload.data : [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

/**
 * Set taskReportByActualInterval error if api fails and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( error => action.payload, loading => false )
 */
export const fetchTaskReportsByActualIntervalRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

/**
 * Set taskReportByCummulativeInterval loading to true when api is inprogress
 * @param state current state
 * @return updated state ( loading => true )
 */
export const fetchTaskReportsByCummulativeIntervalPending = (state: TaskReportState) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    loading: true,
    status: 'idle',
  },
});

/**
 * Set taskReportByCummulativeInterval data fetched from api and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( data => payload.data, loading => false )
 */
export const fetchTaskReportsByCummulativeIntervalFulfilled = (state: TaskReportState, { payload }: FetchTaskReportsByActualIntervalFulfilledAction) => ({
  ...state,
  taskReportByCummulativeInterval: {
    data: payload?.data.length ? payload.data : [],
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

/**
 * Set taskReportByActualInterval error if api fails and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( error => action.payload, loading => false )
 */
export const fetchTaskReportsByCummulativeIntervalRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

/**
 * Set rawTaskReportSync loading to true when api is inprogress
 * @param state current state
 * @return updated state ( loading => true )
 */
export const startRawTaskReportsSyncPending = (state: TaskReportState) => ({
  ...state,
  rawTaskReportsSync: {
    ...state.rawTaskReportsSync,
    loading: true,
    status: 'idle',
  },
});

/**
 * Set rawTaskReportsSync depending on the response
 * @param state current state
 * @param action payload
 * @return updated state ( data => payload.data, loading => false )
 */
export const startRawTaskReportsSyncFulfilled = (state: TaskReportState, { payload }: StartRawTaskReportsSyncFulfilledAction) => {
  if (payload.httpStatus === 200) {
    return {
      ...state,
      rawTaskReportsSync: {
        ...state.rawTaskReportsSync,
        loading: false,
        error: '',
        status: payload.status,
      },
    };
  }
  return {
    ...state,
    rawTaskReportsSync: {
      ...state.rawTaskReportsSync,
      loading: false,
      error: '',
      status: payload.status,
    },
  };
};

/**
 * Set rawTaskReportsSync error if api fails and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( error => action.payload, loading => false )
 */
export const startRawTaskReportsSyncRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  rawTaskReportsSync: {
    ...state.rawTaskReportsSync,
    loading: false,
    error: action.payload?.error,
    status: 'FAILED',
  },
});

/**
 * Set rawTaskReportSync loading to true when api is inprogress
 * @param state current state
 * @return updated state ( loading => true )
 */
export const fetchRawTaskReportsSyncStatusPending = (state: TaskReportState) => ({
  ...state,
  rawTaskReportsSync: {
    ...state.rawTaskReportsSync,
    loading: true,
    status: 'IN_PROGRESS',
  },
});

/**
 * Set rawTaskReportsSync status depending on the response
 * @param state current state
 * @param action payload
 * @return updated state ( data => payload.data, loading => false )
 */
export const fetchRawTaskReportsSyncStatusFulfilled = (state: TaskReportState, { payload }: FetchRawTaskReportsSyncStatusFulfilledAction) => ({
  ...state,
  rawTaskReportsSync: {
    ...state.rawTaskReportsSync,
    status: payload.status,
    loading: false,
  },
});

/**
 * Set rawTaskReportsSyncStatus error if api fails and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( error => action.payload, loading => false )
 */
export const fetchRawTaskReportsSyncStatusRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  rawTaskReportsSync: {
    ...state.rawTaskReportsSync,
    loading: false,
    error: action.payload?.error || 'error',
    status: 'FAILED',
  },
});

/**
 * Set rawTaskReports loading to true when api is inprogress
 * @param state current state
 * @return updated state ( loading => true )
 */
export const fetchRawTaskReportsPending = (state: TaskReportState) => ({
  ...state,
  rawTaskReports: {
    ...state.rawTaskReports,
    loading: true,
    status: 'idle',
  },
});

/**
 * Set rawTaskReports data, total count fetched from api and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( data => payload.data, loading => false )
 */
export const fetchRawTaskReportsFulfilled = (state: TaskReportState, { payload }: FetchRawTaskReportsFulfilledAction) => ({
  ...state,
  rawTaskReports: {
    ...state.rawTaskReports,
    data: payload?.data?.length ? payload.data : [],
    totalCount: payload?.totalCount || 0,
    loading: false,
    error: '',
    httpStatus: 200,
    searchPairData: {
      ...state.rawTaskReports.searchPairData,
    },
    searchOptions: [],
    status: 'succeeded',
  },
});

/**
 * Set rawTaskReports error if api fails and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( error => action.payload, loading => false )
 */
export const fetchRawTaskReportsRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  rawTaskReports: {
    ...state.rawTaskReports,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

export const precompActualIntervalByTypeInitReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    loading: true,
    status: 'idle',
  },
});

export const precompActualIntervalByTypeFulfilledReducer = (state: TaskReportState, { payload }: FetchTaskReportsByActualIntervalFulfilledAction) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    data: payload.data,
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const precompActualIntervalByTypeRejectedReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByActualInterval: {
    ...state.taskReportByActualInterval,
    loading: false,
    error: '',
    httpStatus: 500,
    status: 'failed',
  },
});

export const precompCumulativeIntervalByTypeInitReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    loading: true,
    status: 'idle',
  },
});

export const precompCumulativeIntervalByTypeFulfilledReducer = (state: TaskReportState, { payload }: FetchTaskReportsByActualIntervalFulfilledAction) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    data: payload.data,
    loading: false,
    error: '',
    httpStatus: 200,
    status: 'succeeded',
  },
});

export const precompCumulativeIntervalByTypeRejectedReducer = (state: TaskReportState) => ({
  ...state,
  taskReportByCummulativeInterval: {
    ...state.taskReportByCummulativeInterval,
    loading: false,
    error: '',
    httpStatus: 500,
    status: 'failed',
  },
});

/**
 * Set rawTaskReports searchOptions fetched from api
 * @param state current state
 * @param action payload
 * @return updated state ( searchOptions => payload.data )
 */
export const fetchRawTaskReportsColumnSearchFulfilled = (state: TaskReportState, { payload }: FetchRawTaskReportsColumnsFulfilledAction) => ({
  ...state,
  rawTaskReports: {
    ...state.rawTaskReports,
    searchOptions: payload?.data.length ? payload.data : [],
  },
});

/**
 * Update columnName after user selects an option
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const updateRawTaskReportfilterReducer = (state: TaskReportState, action: rawTaskReportColAction) => ({
  ...state,
  rawTaskReports: {
    ...state.rawTaskReports,
    searchPairData: {
      ...state.rawTaskReports.searchPairData,
      columnName: action.payload,
    },
  },
});

/**
 * Update columnName after user selects an option
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const updatePrecomputeRawTaskReportfilterReducer = (state: TaskReportState, action: rawTaskReportColAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    searchPairData: {
      ...state.precomputerawTaskReports.searchPairData,
      columnName: action.payload,
    },
  },
});

/**
 * Update columnInput after user enters value
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const updateRawTaskReportfilterValueReducer = (state: TaskReportState, action: rawTaskReportColAction) => ({
  ...state,
  rawTaskReports: {
    ...state.rawTaskReports,
    searchPairData: {
      ...state.rawTaskReports.searchPairData,
      columnInput: action.payload,
    },
  },
});

export const updatePrecomputeRawTaskReportfilterValueReducer = (state: TaskReportState, action: rawTaskReportColAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    searchPairData: {
      ...state.precomputerawTaskReports.searchPairData,
      columnInput: action.payload,
    },
  },
});

/**
 * Update filterGroup
 * For same column -> replace the search keyword
 * for new column -> append to the existing list
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const updateRawTaskReportfilterGroupReducer = (state: TaskReportState, action: rawTaskReportfilterGroup) => {
  let updatedFilterGroup = state.rawTaskReports.filterGroup.filter((col) => col.columnName !== action.payload[0].columnName);
  updatedFilterGroup = updatedFilterGroup.concat(action.payload);
  return {
    ...state,
    rawTaskReports: {
      ...state.rawTaskReports,
      filterGroup: updatedFilterGroup,
    },
  };
};

export const updatePrecomputeRawTaskReportfilterGroupReducer = (state: TaskReportState, action: rawTaskReportfilterGroup) => {
  let updatedFilterGroup = state.precomputerawTaskReports.filterGroup.filter((col) => col.columnName !== action.payload[0].columnName);
  updatedFilterGroup = updatedFilterGroup.concat(action.payload);
  return {
    ...state,
    precomputerawTaskReports: {
      ...state.precomputerawTaskReports,
      filterGroup: updatedFilterGroup,
    },
  };
};

/**
 * delete elemet from filterGroup
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const deleteRawTaskReportfilterGroupReducer = (state: TaskReportState, action: rawTaskReportfilterGroup) => ({
  ...state,
  rawTaskReports: {
    ...state.rawTaskReports,
    filterGroup: state.rawTaskReports.filterGroup.filter((element) => element.columnName !== action.payload[0].columnName),
  },
});

export const deletePrecomputeRawTaskReportfilterGroupReducer = (state: TaskReportState, action: rawTaskReportfilterGroup) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    filterGroup: state.precomputerawTaskReports.filterGroup.filter((element) => element.columnName !== action.payload[0].columnName),
  },
});
/**
 * clear filterGroup
 * @param state current state
 * @returns updated state
 */
export const clearRawTaskReportfilterGroupReducer = (state: TaskReportState) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    filterGroup: [],
  },
});

/**
 * clear task report
 * @param state current state
 * @returns updated state
 */
export const clearTaskReportfilterGroupReducer = (state: TaskReportState) => ({
  ...state,
  loading: initialState.loading,
  error: initialState.error,
  httpStatus: initialState.httpStatus,
  status: initialState.status,
  taskInfo: initialState.taskInfo,
});

/**
 * Update filterId and taskNumber for performance tuning ( For avoiding repitition of API calls )
 * @param state current state
 * @param action payload
 * @returns updated state
 */
export const updateTaskReportPerformanceTuningReducer = (state: TaskReportState, { payload }: UpdatePerformanceTuningFulfilledAction) => ({
  ...state,
  performance: {
    filterId: payload.filterId || state.performance.filterId,
    taskNumber: payload.taskNumber || state.performance.taskNumber,
    table: payload.table,
  },
});

export const updatePrecomputeTaskReportPerformanceTuningReducer = (state: TaskReportState, { payload }: UpdatePerformanceTuningFulfilledAction) => ({
  ...state,
  performance: {
    filterId: payload.filterId || state.performance.filterId,
    taskNumber: payload.taskNumber || state.performance.taskNumber,
    table: payload.table,
  },
});

/**
 * reset metadata api response status
 * @param state current state
 * @returns updated state
 */
export const resetMetaDataReducer = (state: TaskReportState) => ({
  ...state,
  error: '',
  httpStatus: initialState.httpStatus,
});

export const clearPrevfilterIdReducer = (state: TaskReportState) => ({
  ...state,
  performance: initialState.performance,
});
/**
 * Set precomputerawTaskReports loading to true when api is inprogress
 * @param state current state
 * @return updated state ( loading => true )
 */
export const fetchPrecomputeRawTaskReportsPending = (state: TaskReportState) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    loading: true,
    status: 'idle',
  },
});

/**
 * Set precomputerawTaskReports data, total count fetched from api and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( data => payload.data, loading => false )
 */
export const fetchPrecomputeRawTaskReportsFulfilled = (state: TaskReportState, { payload }: FetchPrecomputeRawTaskReportsFulfilledAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    data: payload?.data?.table?.length ? payload.data.table : [],
    totalCount: payload?.data?.count || 0,
    loading: false,
    error: '',
    httpStatus: 200,
    searchPairData: {
      ...state.precomputerawTaskReports.searchPairData,
    },
    searchOptions: [],
    status: 'succeeded',
  },
});

/**
 * Set precomputerawTaskReports error if api fails and loading to false
 * @param state current state
 * @param action payload
 * @return updated state ( error => action.payload, loading => false )
 */
export const fetchPrecomputeRawTaskReportsRejected = (state: TaskReportState, action: APIRejectedAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    loading: false,
    error: action.payload?.error,
    httpStatus: action.payload?.httpStatus,
    status: 'failed',
  },
});

/**
 * Set rawTaskReports searchOptions fetched from api
 * @param state current state
 * @param action payload
 * @return updated state ( searchOptions => payload.data )
 */
export const fetchPrecomputeRawTaskReportsColumnSearchFulfilled = (state: TaskReportState, { payload }: FetchPrecomputeRawTaskReportsFulfilledAction) => ({
  ...state,
  precomputerawTaskReports: {
    ...state.precomputerawTaskReports,
    searchOptions: payload?.data?.table?.length ? payload.data.table : [],
  },
});
