import React from 'react';
import { ErrorOutline, WarningOutline } from '@airbus/icons/react';
import './NoData.scss';

type emptyDataPropsType = {
  message: string;
  type ?: string;
  customClassName ?: string;
};

function NoData(props: emptyDataPropsType) {
  const { message, type, customClassName } = props;
  return (
    <div className={`no-data-component-cls ${customClassName}`}>
      <span>
        {type === 'error' ? (
          <div className="error-content">
            {' '}
            <ErrorOutline />
            {' '}
          </div>
        ) : (
          <div className="warning-content">
            {' '}
            <WarningOutline />
            {' '}
          </div>
        ) }
        { message }
      </span>
    </div>
  );
}

NoData.defaultProps = {
  type: 'no-data',
  customClassName: '',
};

export default NoData;
