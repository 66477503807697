import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const SignIn = () => {
  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    Auth.federatedSignIn().catch((e) => {});
  });
  return null;
};

export default SignIn;
