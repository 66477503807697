/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import PERMISSION_MAPPER from '../commonUtils/PermissionMapper';
import { store } from '../../store/store';

interface FeaturePermissions {
  [key: string]: string[];
}

/**
 * Returns true if the user have access to the page/component
 * @param permissionFor string - request page access.Defined in PERMISSION_MAPPER util
 * @returns boolean
 */
const useUserPermissionCheck = (permissionFor: string) => {
  const [isAllowed, setIsAllowed] = useState(false);
  const allowedPermissions = store.getState().userPermissions.featurePermissions;

  useEffect(() => {
    const permissions: string[] = PERMISSION_MAPPER[permissionFor];
    const groupSasPermissionByCode: FeaturePermissions = allowedPermissions.reduce((acc: FeaturePermissions, obj: any) => {
      const key = obj.sasPermissionCode;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj.featureCode);
      return acc;
    }, {});
    const myPermission = permissions?.find((permission: string) => groupSasPermissionByCode[permission]);
    // allow access atleast if 1 permission is present
    if (myPermission?.length) {
      setIsAllowed(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAllowed;
};

export default useUserPermissionCheck;
