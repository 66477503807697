/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { OPERATOR_FINDING_RATE_TADA } from '../../models/programAnalysis/constants';
import {
  AC_AGE,
  AC_AGE_FINDING_RATE,
  AVERAGE_OF_OI_BY_YEAR_FW,
  AVERAGE_OF_OI_BY_YEAR_OPERATOR,
  END_DATE,
  MAX_AC_AGE,
  NB_OF_OI,
  NB_OF_OI_BY_AC_AGE,
  NB_OF_OI_IN_CANCELLATION,
  NB_OF_OI_IN_DELAY,
  NB_OF_OI_IN_DIVERSION,
  NB_OF_OI_IN_FLIGHT_TURN_BACK,
  OI_AVERAGE_DELAY_IN_MINUTES,
  OI_DURATION,
  OPERATOR_FINDING_RATE,
  RSI_BY_AC_AGE,
  RSI,
  START_DATE,
  YEAR_FLEET_WIDE,
  YEAR_OPERATOR,
  AC_AGE_FINDING_RATE_TADA,
} from '../../models/unscheduledEventsModel/constants';

const basicGraphConfig = {
  options: {
    hideLegends: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  },
};
// Utils used only in this file by the exported functions

/**
 * Creates an array of 10 intervals based on the range of ages contained in the param
 *
 * @param ageArray An array of numbers that are ages
 * @returns An array of 10 intervals
 */
const getRanges = (maxAge: number): any => {
  const intervalSize = maxAge / 10;
  const intervals = [];

  for (let i = 0; i < 10; i += 1) {
    const start = i * intervalSize;
    const end = start + intervalSize;
    if (maxAge < 10 || maxAge % 10 !== 0) intervals.push([start.toFixed(1), end.toFixed(1)]);
    else intervals.push([start, end]);
  }

  return intervals;
};

/**
 * Creates formatted finding rates as an array of numbers
 *
 * @param ageArray A 2D array of numbers that represent finding rate
 * @returns An array of finding rate avg for 10 intervals
 */
const getFormattedFrSums = (frSums: number[][]): number[] => {
  return frSums.map((item) => {
    return item[1] === 0 ? 0 : item[0] / item[1];
  });
};

/**
 * Orders and format data to be displayed in each age range.
 * It sums up all the data in each corresponding range
 * For the variable frSums we use an array for each range because it needs to be a % at the end. So we divide the sum by the number of numbers we summed up.
 *
 * @param ageArray An array of ages
 * @param dataArray An array of numbers, the order correponds to agesArray's order
 * @param ageArrayFindingRate An array of ages
 * @param dataArrayFindingRate An array of numbers, the order correponds to ageArrayFindingRate's order
 * @returns An object with the ranges and ordered data
 */
const formatDataOiVsFindingRate = (ageArray: number[], dataArray: number[], ageArrayFindingRate: number[], dataArrayFindingRate: number[], maxAge: number): any => {
  const biggerAgeArray = ageArray.length >= ageArrayFindingRate.length ? ageArray : ageArrayFindingRate;
  const intervals = getRanges(maxAge);
  const oiSums = Array(10).fill(0);
  const frSums = [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ];

  if (dataArray.length === 0) {
    return { intervals: [], oiSums: [], formattedFrSums: [] };
  }
  for (let i = 0; i < biggerAgeArray.length; i += 1) {
    // intervals values are converted to numbers for comparison with age.
    for (let j = 0; j < intervals.length; j += 1) {
      if (ageArray[i] >= +intervals[j][0] && ageArray[i] < +intervals[j][1]) {
        oiSums[j] += dataArray[i];
      }
      if (ageArrayFindingRate[i] && ageArrayFindingRate[i] >= +intervals[j][0] && ageArrayFindingRate[i] < +intervals[j][1]) {
        frSums[j][0] += dataArrayFindingRate[i];
        frSums[j][1] += 1;
      }
    }
  }

  const formattedFrSums = getFormattedFrSums(frSums);

  for (let i = 0; i < intervals.length; i += 1) {
    intervals[i] = `${intervals[i][0]} - ${intervals[i][1]}`;
  }

  return { intervals, oiSums, formattedFrSums };
};

/**
 * Orders and format data to be displayed in each age range.
 * It sums up all the data in each corresponding range
 *
 * @param ageArray An array of ages
 * @param oiArray An array of numbers, the order correponds to agesArray's order
 * @param rsiArray An array of numbers, the order correponds to ageArray's order
 * @returns An object with the ranges and ordered data
 */
const formatDataOiVsRSI = (ageArray: number[], oiArray: number[], rsiArray: number[], maxAge: number): any => {
  const intervals = getRanges(maxAge);
  const rsiSums = Array(10).fill(0);
  const oiSums = Array(10).fill(0);
  const rsiNbToDivide = Array(10).fill(0);

  if (oiArray.length === 0 || rsiArray.length === 0) {
    return { intervals: [], rsiSums: [], oiSums: [] };
  }

  for (let i = 0; i < ageArray.length; i += 1) {
    for (let j = 0; j < intervals.length; j += 1) {
      if (ageArray[i] >= intervals[j][0] && ageArray[i] < intervals[j][1]) {
        oiSums[j] += oiArray[i];
      }
      if (ageArray[i] >= intervals[j][0] && ageArray[i] < intervals[j][1]) {
        rsiSums[j] += rsiArray[i];
        rsiNbToDivide[j] += 1;
      }
    }
  }

  for (let i = 0; i < rsiSums.length; i += 1) {
    if (rsiNbToDivide[i] !== 0) rsiSums[i] /= rsiNbToDivide[i];
  }

  for (let i = 0; i < intervals.length; i += 1) {
    intervals[i] = `${intervals[i][0]} - ${intervals[i][1]}`;
  }

  return { intervals, rsiSums, oiSums };
};

/**
 * Format data to be passed to the graph on the frontend
 *
 * @param data The data needed by the graph
 * @param years The dates correponding to the data param
 * @param title The title of the graph
 * @param max The max value on y scale for this graph
 * @returns An object with the data to pass to the graph on the frontend
 */
const createGraphReportAvgOI = (data: number[], columnData: number[], years: number[], title: string, max: number): any => {
  return {
    data: {
      labels: years,
      datasets: [
        {
          label: 'Avg number of OI reported per A/C for fleet wide',
          data,
          backgroundColor: 'rgb(53, 167, 233)',
          barPercentage: 0.67,
          categoryPercentage: 0.45,
        },
        {
          label: 'Avg number of OI reported per A/C operator',
          data: columnData,
          backgroundColor: 'rgb(37, 95, 204)',
          barPercentage: 0.67,
          categoryPercentage: 0.45,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        y: {
          title: {
            display: true,
            text: 'Avg number of OI reported',
            font: {
              weight: 'bold',
            },
          },
          max: Math.abs(max + max / 10),
        },
        x: {
          title: {
            display: true,
            text: 'Years',
            font: {
              weight: 'bold',
            },
          },
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          position: 'bottom' as const,
          align: 'start',
        },
      },
    },
    chartTitle: title,
    height: 300,
  };
};

/**
 * Format data to be passed to the graph on the frontend
 *
 * @param formattedData The data needed by the graph in correct format
 * @param max The max value on y scale for this graph and OI VS RSI graph
 * @returns An object with the data to pass to the graph on the frontend
 */
const createGraphOiVsRSI = (formattedData: any, max: number): any => {
  const graphData = {
    ...basicGraphConfig,
    options: {
      ...basicGraphConfig.options,
      scales: {
        x: {
          title: {
            display: true,
            text: 'A/C Age (Years)',
            font: {
              weight: 'bold',
            },
          },
        },
      },
    },
    chartTitle: 'OI Reported vs RSI per A/C age',
  };

  const fullData = Object.values(formattedData).every((item) => Array.isArray(item) && item.length !== 0);
  if (!fullData) return graphData;

  return {
    ...graphData,
    data: {
      labels: formattedData.intervals,
      datasets: [
        {
          order: 2,
          label: 'Number of OI reported',
          type: 'bar',
          data: formattedData.oiSums,
          backgroundColor: 'rgba(37, 95, 204, 1)',
        },
        {
          order: 1,
          label: 'RSI',
          type: 'line',
          data: formattedData.rsiSums,
          borderColor: 'rgba(46, 159, 224, 1)',
          backgroundColor: 'rgba(46, 159, 224, 1)',
          yAxisID: 'rsiAxis',
        },
      ],
    },
    options: {
      ...graphData.options,
      scales: {
        ...graphData.options.scales,
        y: {
          title: {
            display: true,
            text: 'Number of OI reported',
            font: {
              weight: 'bold',
            },
          },
          max: Math.abs(max),
        },
        rsiAxis: {
          title: {
            display: true,
            text: 'RSI',
          },
          grid: {
            display: false,
          },
          position: 'right',
          max: 10,
        },
      },
    },
  };
};

/**
 * Orders and format data to be displayed in each age range.
 * It sums up all the data in each corresponding range
 *
 * @param formattedData The data needed by the graph in correct format
 * @param max The max value on y scale for this graph and OI VS RSI graph
 * @param isLsdrTask A boolean to indicate if the task is of type LSDR
 * @returns An object with the data to pass to the graph on the frontend
 */
const createGraphOiVsFindingRate = (formattedData: any, max: number, isLsdrTask: boolean): any => {
  const graphData = {
    ...basicGraphConfig,
    options: {
      ...basicGraphConfig.options,
      scales: {
        x: {
          title: {
            display: true,
            text: 'A/C Age (Years)',
            font: {
              weight: 'bold',
            },
          },
        },
      },
    },
    chartTitle: 'OI Reported vs Finding Rate per A/C age',
  };
  const fullData = Object.values(formattedData).every((item) => Array.isArray(item) && item.length !== 0);
  if (!fullData) return graphData;

  let maxY1 = Math.max(...formattedData.formattedFrSums);
  if (maxY1 < 98) maxY1 += 2;
  return {
    ...graphData,
    data: {
      labels: formattedData.intervals,
      datasets: [
        {
          order: 2,
          label: 'Number of OI reported',
          type: 'bar',
          data: formattedData.oiSums,
          backgroundColor: 'rgba(37, 95, 204, 1)',
        },
        ...(isLsdrTask
          ? [
            {
              order: 1,
              label: 'Finding Rate',
              type: 'line',
              data: formattedData.formattedFrSums,
              borderColor: 'rgba(46, 159, 224, 1)',
              backgroundColor: 'rgba(46, 159, 224, 1)',
              yAxisID: 'fRateAxis',
            },
          ]
          : []),
      ],
    },
    options: {
      ...graphData.options,
      scales: {
        ...graphData.options.scales,
        y: {
          title: {
            display: true,
            text: 'Number of OI reported',
            font: {
              weight: 'bold',
            },
          },
          max: Math.abs(max),
        },
        ...(isLsdrTask
          ? {
            fRateAxis: {
              title: {
                display: true,
                text: 'Finding Rate',
                font: {
                  weight: 'bold',
                },
              },
              grid: {
                display: false,
              },
              position: 'right',
              max: maxY1,
            },
          }
          : {}),
      },
    },
  };
};

/**
 * Creates an array of years
 *
 * @param startDate The starting year (format X/XXXX)
 * @param endDate The ending year (format X/XXXX)
 * @returns An array with all the years between start and end, in ascending order
 */
const createYearsRange = (startDate: string, endDate: string): any => {
  // The + syntax is used to cast the result as a number
  if (!startDate || !endDate) return [];
  const start: number = +startDate.slice(-4);
  const end: number = +endDate.slice(-4);
  const yearsRange = [];

  for (let i = start; i <= end; i += 1) {
    yearsRange.push(i);
  }

  return yearsRange;
};

/**
 * Orders data according to an ordered array of years. If there is no data for
 * one year, the value is set to 0 so there are no gaps
 *
 * @param yearsRange Ordered array of years
 * @param dataArray The data to sort
 * @param yearArray The years corresponding to the data to sort
 * @param dates An array of two dates, start and end
 * @returns An object containing ordered data and formatted years
 */
const orderDataByYear = (yearsRange: Array<any>, dataArray: number[], yearArray: number[], dates: string[]): any => {
  const orderedData = [];
  const formattedYearsRange = [...yearsRange];

  if (dataArray.length === 0) {
    return { orderedData: [], yearsRange: [] };
  }
  for (let i = 0; i < yearsRange.length; i += 1) {
    const yearIndex = yearArray.indexOf(yearsRange[i]);
    orderedData[i] = yearIndex === -1 ? 0 : dataArray[yearIndex];
  }

  formattedYearsRange.splice(0, 1, dates[0]);
  formattedYearsRange.splice(formattedYearsRange.length - 1, 1, dates[1]);
  return { orderedData, yearsRange: formattedYearsRange };
};

// Exported functions used in UnscheduledEvents

/**
 * Format data and create arrays to be passed to graphs in the frontend
 *
 * @param data All the data of the current OI
 * @returns An object containing data for two linked graphs
 */
export const createGraphsReportAvgOI = (data: oiAta2dFetchData): any => {
  const yearsRange = createYearsRange(data[START_DATE], data[END_DATE]);
  const maxValueAvgOi = Math.max(...data[AVERAGE_OF_OI_BY_YEAR_FW].concat(data[AVERAGE_OF_OI_BY_YEAR_OPERATOR]));

  const orderedDataFW = orderDataByYear(yearsRange, data[AVERAGE_OF_OI_BY_YEAR_FW], data[YEAR_FLEET_WIDE], [data[START_DATE], data[END_DATE]]);
  const orderedDataOperator = orderDataByYear(yearsRange, data[AVERAGE_OF_OI_BY_YEAR_OPERATOR], data[YEAR_OPERATOR], [data[START_DATE], data[END_DATE]]);

  const perFleetWide = createGraphReportAvgOI(orderedDataFW.orderedData, orderedDataOperator.orderedData, orderedDataFW.yearsRange, 'Comparison of OI reported per A/C between fleet wide and operator', maxValueAvgOi);
  return perFleetWide;
};

/**
 * Formats an array to display correctly data on the header of the page
 *
 * @param data Object containing all the OI data
 * @returns An array with the dynamic datas
 */
export const createHeaderCardArray = (data: oiAta2dFetchData, premiumFeature: boolean): any => {
  return [
    {
      label: 'Total Operational Interruption',
      type: 'Label',
      value: data[NB_OF_OI],
      isPremium: premiumFeature,
    },
    {
      label: 'In Flight Turn Back',
      type: 'Label',
      value: data[NB_OF_OI_IN_FLIGHT_TURN_BACK],
      isPremium: premiumFeature,
    },
    {
      label: 'Delay',
      type: 'Label',
      value: data[NB_OF_OI_IN_DELAY],
      isPremium: premiumFeature,
    },
    {
      label: 'Relative Severity Index',
      type: 'Label',
      value: data[RSI] ? data[RSI] : '-',
      isPremium: premiumFeature,
    },
    {
      label: 'Diversion',
      type: 'Label',
      value: data[NB_OF_OI_IN_DIVERSION],
      isPremium: premiumFeature,
    },
    {
      label: 'Cancellation',
      type: 'Label',
      value: data[NB_OF_OI_IN_CANCELLATION],
      position: 6,
      isPremium: premiumFeature,
    },
    {
      label: 'Average delays (minutes)',
      type: 'Label',
      value: Math.round(data[OI_AVERAGE_DELAY_IN_MINUTES]),
      isPremium: premiumFeature,
    },
  ];
};

export const oldCreateHeaderCardArray = (data: oiAta2dFetchData): any => {
  return [
    {
      label: 'Total Operational Interruption',
      type: 'Label',
      value: data[NB_OF_OI],
    },
    {
      label: 'In Flight Turn Back',
      type: 'Label',
      value: data[NB_OF_OI_IN_FLIGHT_TURN_BACK],
    },
    {
      label: 'Delay',
      type: 'Label',
      value: data[NB_OF_OI_IN_DELAY],
    },
    {
      label: 'Risk Severity Index',
      type: 'Label',
      value: 'XXX',
      isPremium: true,
    },
    {
      label: 'Diversion',
      type: 'Label',
      value: data[NB_OF_OI_IN_DIVERSION],
    },
    {
      label: 'Cancellation',
      type: 'Label',
      value: data[NB_OF_OI_IN_CANCELLATION],
      position: 6,
    },
    {
      label: 'Average delays (minutes)',
      type: 'Label',
      value: Math.round(data[OI_AVERAGE_DELAY_IN_MINUTES]),
    },
    {
      label: 'At ATA 2D',
      type: 'ButtonGroup',
      groupButtonOption: [
        {
          label: 'At ATA 2D',
          pressed: true,
          icon: false,
          name: 'ataLevel',
        },
        {
          label: 'At Task Level',
          pressed: false,
          icon: true,
          name: 'taskLevel',
        },
      ],
    },
  ];
};

/**
 * Format data to be passed to the graph on the frontend
 *
 * @param data An object containing all the OI data
 * @param isLsdrTask A boolean to indicate is the task is of type LSDR
 * @returns An object with the data to pass to both graphs on the frontend
 */
export const createGraphOiVsOtherData = (dataAta2d: oiAta2dFetchData, dataTask: oiTaskFetchData, isLsdrTask: boolean, enhancedAnalysis: boolean): any => {
  const formattedDataFindingRate = formatDataOiVsFindingRate(dataAta2d[AC_AGE], dataAta2d[NB_OF_OI_BY_AC_AGE], (enhancedAnalysis ? (dataTask[AC_AGE_FINDING_RATE_TADA] ? dataTask[AC_AGE_FINDING_RATE_TADA] : []) : dataTask[AC_AGE_FINDING_RATE]), (enhancedAnalysis ? (dataTask[OPERATOR_FINDING_RATE_TADA] ? dataTask[OPERATOR_FINDING_RATE_TADA] : []) : dataTask[OPERATOR_FINDING_RATE]), dataAta2d[MAX_AC_AGE] || 10);
  const formattedDataRSI = formatDataOiVsRSI(dataAta2d[AC_AGE], dataAta2d[NB_OF_OI_BY_AC_AGE], dataAta2d[RSI_BY_AC_AGE], dataAta2d[MAX_AC_AGE] || 10);
  const max = Math.max(...formattedDataFindingRate.oiSums.concat(formattedDataRSI.oiSums));

  return {
    findingRate: createGraphOiVsFindingRate(formattedDataFindingRate, Math.abs(max + max / 10), isLsdrTask),
    rsi: createGraphOiVsRSI(formattedDataRSI, Math.abs(max + max / 10)),
  };
};

/**
 * Format data to be passed to the table on the frontend.
 * It simply replaces the OI_DURATION field by N/A if it is set at -1s
 *
 * @param tableData An object containing all the table data
 * @returns An array of all the formatted lines needed by the table
 */
export const formatTableArray = (tableData: tableFetchData[]): any => {
  const newTableData = tableData.map((item) => {
    const tmp = { ...item };
    if (item[OI_DURATION] === -1) {
      tmp[OI_DURATION] = 'N/A';
    }
    return tmp;
  });
  return newTableData;
};

export const filterTable = (tableData: tableFetchData[], filters: searchPair[]): any => {
  let newArr = [...tableData];
  filters.forEach((fil) => {
    newArr = newArr.filter((item) => item[fil.columnName] === fil.columnInput);
  });
  return newArr;
};
