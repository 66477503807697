import React from 'react';
import { Tooltip } from '@airbus/components-react';
import HeaderComponent from '../../Shared/DataTable/DataTableHeader';
import RadioButton from '../../Shared/DataTable/RadioButton';
import {
  FLEET_AVG_YIELD,
  lsdrTasks,
  MPD_TASK_CODE,
  MPD_TASK_DESCRIPTION,
  MPD_TASK_REFERENCE,
  NUMBER_OF_TASK_REPORT, OP_AVG_YIELD, OPERATOR_FINDING_RATE, SOURCE_DOCUMENT_TYPE, WORLD_WIDE_FINDING_RATE, ENRICH_TASK_OVERVIEW,
} from '../../../models/programAnalysis/constants';
import { maintenanceTask, taskListSort } from '../../../models/programAnalysis/programAnalysisTypes';
import { applySort } from './utils';
import { featureSwitchConfig } from '../../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { checkFeaturePermission } from '../../../utils/userPermissionUtils/userPermissionUtils';
import './TaskList.scss';

// Creating a custom logic to hide the column level based on the user permission
const getColumn = (
  title: string,
  id: string,
  accessor: string,
  minWidth: number,
  maxWidth: number,
  align: string,
): ColumnDefinition => {
  const hasPermission = checkFeaturePermission([ENRICH_TASK_OVERVIEW]);

  if (!hasPermission) {
    return {
      Header: <HeaderComponent title={title} premium />,
      id,
      accessor,
      minWidth,
      maxWidth,
      align,
      Cell: <div className="table-value-blur">hide</div>,
    };
  }

  return {
    Header: <HeaderComponent title={title} premium />,
    id,
    accessor,
    minWidth: 85,
    maxWidth: 85,
    align,
    Cell: '',
  };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wwsfrTooltip = (row: any, field: string) => {
  let textToDisplay = '';
  if (lsdrTasks.includes(row.original[MPD_TASK_CODE])) {
    textToDisplay = 'LSDR Task';
  }
  let result;
  if (!Number.isNaN(Number.parseFloat(row.original[field]))) {
    result = `${row.original[field]}%`;
  } else if (textToDisplay === '') {
    result = (<span> N/A </span>);
  } else {
    result = (
      <Tooltip
        placement="left"
        label={textToDisplay}
        className="custom-tooltip"
        arrowClassName="custom-tooltip-arrow"
        targetClassName="custom-tooltip-target"
      >
        <span> N/A </span>
      </Tooltip>
    );
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const osfrTooltip = (row: any, field: string) => {
  let textToDisplay = '';
  if (lsdrTasks.includes(row.original[MPD_TASK_CODE])) {
    textToDisplay = 'LSDR Task';
  } else if (!row.original[NUMBER_OF_TASK_REPORT]) {
    textToDisplay = 'No reporting data';
  }
  return textToDisplay === '' ? `${row.original[field]}%` : (
    <Tooltip
      placement="left"
      label={textToDisplay}
      className="custom-tooltip"
      arrowClassName="custom-tooltip-arrow"
      targetClassName="custom-tooltip-target"
    >
      <span>
        N/A
      </span>
    </Tooltip>
  );
};

export const sortTableData = (data: maintenanceTask[], dataSortBy: taskListSort[]) => {
  let sortedData = [];
  if (dataSortBy?.length > 1) {
    sortedData = applySort(data, dataSortBy.filter((item) => item.id !== MPD_TASK_REFERENCE) as taskListSort[]);
  } else {
    sortedData = applySort(data, dataSortBy as taskListSort[]);
  }
  return sortedData;
};

/**
 * This table is used to define the columns the DataTable in MaintenanceTaskTable will have.
 */

export const getTableColumns = (selectedMPD: string, userType: string) => {
  // Default Table Columns - "TASK NUMBER", "TASK DESCRIPTION","TASK CODE", "SOURCE", "WWFR", "OPSFR", "OPERATOR ENHANCED FINDING RATE"
  const defaultTableColumns = [
    {
      Header: '',
      id: 'selected',
      accessor: 'selected',
      width: 0,
      minWidth: 6,
      maxWidth: 6,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => <RadioButton isSelected={row.original.mpd_task_reference === selectedMPD} />,
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="Task Number" />,
      id: MPD_TASK_REFERENCE,
      accessor: MPD_TASK_REFERENCE,
      width: 100,
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="Task Description" />,
      accessor: MPD_TASK_DESCRIPTION,
      width: 200,
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="Task Code" />,
      accessor: MPD_TASK_CODE,
      id: MPD_TASK_CODE,
      width: 60,
      maxWidth: 60,
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="Source" />,
      accessor: SOURCE_DOCUMENT_TYPE,
      id: SOURCE_DOCUMENT_TYPE,
      width: 40,
      maxWidth: 40,
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="World Wide Sample Finding Rate" />,
      id: WORLD_WIDE_FINDING_RATE,
      accessor: (row: maintenanceTask) => (row[WORLD_WIDE_FINDING_RATE] ? row[WORLD_WIDE_FINDING_RATE].toString().concat('%') : 'N/A'),
      minWidth: 75,
      maxWidth: 75,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => wwsfrTooltip(row, WORLD_WIDE_FINDING_RATE),
    },
    {
      Header: <HeaderComponent title="Operator Sample Finding Rate" />,
      id: OPERATOR_FINDING_RATE,
      accessor: (row: maintenanceTask) => (row[OPERATOR_FINDING_RATE] ? row[OPERATOR_FINDING_RATE].toString().concat('%') : 'N/A'),
      minWidth: 70,
      maxWidth: 70,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => osfrTooltip(row, OPERATOR_FINDING_RATE),
    },
  ];

  // New Table Columns - "TASK NUMBER", "TASK DESCRIPTION","TASK CODE", "SOURCE", "WWFR", "OPSFR", "OPERATOR YIELD", "WORLD WIDE FLEET YIELD", "OPERATOR ENHANCED FINDING RATE", "NO.OF PRE-IDENTIFIED OI"
  const newTableColumns = [
    ...defaultTableColumns,
    {
      Header: <HeaderComponent title="Operator Yield" />,
      id: OP_AVG_YIELD,
      // eslint-disable-next-line no-nested-ternary
      accessor: (row: maintenanceTask) => (row[OP_AVG_YIELD] ? row[OP_AVG_YIELD].toString().concat('%') : 'P/A'),
      minwidth: 70,
      maxWidth: 70,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => osfrTooltip(row, OP_AVG_YIELD),
    },
    {
      Header: <HeaderComponent title="Worldwide Fleet Yield" />,
      id: FLEET_AVG_YIELD,
      accessor: (row: maintenanceTask) => (row[FLEET_AVG_YIELD] ? row[FLEET_AVG_YIELD].toString().concat('%') : 'N/A'),
      minWidth: 85,
      maxWidth: 85,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => wwsfrTooltip(row, FLEET_AVG_YIELD),
    },
    // {
    //   Header: <HeaderComponent title="Operator Enhanced Finding Rate" premium />,
    //   id: 'oeFR',
    //   accessor: 'oeFR',
    //   minWidth: 85,
    //   maxWidth: 85,
    //   align: 'left',
    //   disableSortBy: true,
    // },
    // {
    //   Header: <HeaderComponent title="Number of Pre-identified OI" premium />,
    //   id: 'Oi',
    //   accessor: 'Oi',
    //   minWidth: 85,
    //   maxWidth: 85,
    //   align: 'left',
    //   disableSortBy: true,
    // },
    getColumn('Operator Enhanced Finding Rate', 'oeFR', 'oeFR', 85, 85, 'left'),
    getColumn('Number of Pre-identified OI', 'Oi', 'Oi', 85, 85, 'left'),
  ];
  /* feature toggling the table columns based on maintanence-task-list-new-columns featureToggle
   * for internal - newTableColumns
   * for external - defaultTableColumns */
  return featureSwitchConfig(
    { name: 'maintanence-task-list-new-columns', userType },
    newTableColumns,
    defaultTableColumns,
  );
};
