import { NotificationsState, ReadNotifications } from './BellNotificationTypes';

export const bellNotificationReducerFulfilled = (state: NotificationsState, action: { payload: ReadNotifications }) => ({
  ...state,
  notifications: [...state.notifications, ...action.payload.notifications],
  unreadCount: action.payload.unreadCount,
});

export const clearNotificationDataReducer = (state: NotificationsState) => ({
  ...state,
  notifications: [],
  isScrollStopped: false,
});

export const stopNotificationReducer = (state: NotificationsState) => ({
  ...state,
  isScrollStopped: true,
});
