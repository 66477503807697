import React, { useCallback, useEffect, useState } from 'react';
import {
  Col, Row, Spinner,
} from '@airbus/components-react';
import MpdRepositoryAnalysisCard from '../../MpdRepositoryAnalysisCard/MpdRepositoryAnalysisCard';
import './BuildListContainer.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import {
  PARepositoryData,
  PARepositoryFilter,
  PARepositoryState,
} from '../../../models/programAnalysisRepository/PARepositoryTypes';
import { RootState } from '../../../store/store';
import { fetchPARepositoryData } from '../../../models/programAnalysisRepository/PARepositoryAsyncThunk';
import {
  UPDATE_TIME, ID, STATUS, TITLE,
  CREATION_TIME,
} from '../../../models/programAnalysisRepository/constants';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';
import MpdEmptyDataComponent from '../../Shared/MpdEmptyDataComponent/MpdEmptyDataComponent';
import { POSSIBLE_BUILD_STATUS } from '../../../models/programAnalysis/constants';
import { NEW_ARCH } from '../../TaskReportAnalysis/constants';

const BuildListContainer = () => {
  const architecture = secureLS.get('architecture');
  const dispatch = useAppDispatch();
  const operator = secureLS.get('operator');
  const acProgram = secureLS.get('ac_program');
  const repositoryDataObject: PARepositoryState = useAppSelector((state: RootState) => state.PARepository);
  const [page, setPage] = useState(1);
  const [pollingCount, setPollingCount] = useState(0);
  const rowsPerPage = 10;
  const subTitle = 'You can create analysis from Program Analysis';
  const title = 'Saved results not available';
  const iconName = 'AssignmentLateOutline';
  const [dataDisplay, setDataDisplay] = useState<JSX.Element[] | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchPARepositoryData(operator, acProgram)());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (repositoryDataObject.rebulidLoading === 'done') {
      dispatch(fetchPARepositoryData(operator, acProgram)());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, repositoryDataObject.rebulidLoading]);

  const conditionStatusBased = (filters: PARepositoryFilter, item: PARepositoryData) => {
    const columnValue = item[filters.columnFilter];
    const filterValue = filters.value;

    return (
      filterValue === POSSIBLE_BUILD_STATUS.IN_PROGRESS
        && (
          columnValue === POSSIBLE_BUILD_STATUS.IN_PROGRESS
          || columnValue === POSSIBLE_BUILD_STATUS.INIT
        )
    ) || (columnValue === filterValue);
  };

  const dataCompute = useCallback(() => {
    if (repositoryDataObject.loading) {
      return undefined;
    }
    const { filters } = repositoryDataObject;
    const sortByUpdateTime = [...repositoryDataObject.repositoryData].sort((item1, item2) => {
      return item1[UPDATE_TIME] >= item2[UPDATE_TIME] ? -1 : 1;
    });
    const filteredData = filters ? sortByUpdateTime.filter((item) => {
      if (filters.columnFilter === 'status') {
        return conditionStatusBased(filters, item) && item;
      }
      return (item[filters.columnFilter as string].includes(filters.value) && item) || undefined;
    }) : sortByUpdateTime;
    if (architecture === NEW_ARCH) {
      return filteredData.map((item) => (
        <MpdRepositoryAnalysisCard
          buildName={item[TITLE]}
          createdOn={item[CREATION_TIME]}
          updatedOn={item[UPDATE_TIME]}
          requestStatus={item[STATUS]}
          id={item[ID]}
          key={item[ID]}
          pollingCount={pollingCount}
          setPollingCount={setPollingCount}
        />
      ));
    } return filteredData.map((item) => (
      <MpdRepositoryAnalysisCard
        buildName={item[TITLE]}
        createdAt={item[UPDATE_TIME]}
        requestStatus={item[STATUS]}
        id={item[ID]}
        key={item[ID]}
      />
    ));
  }, [architecture, pollingCount, repositoryDataObject]);

  useEffect(() => {
    const sliceDataCompute = dataCompute(); // for time being we'll show all the records and implement the pagination in later stage
    setDataDisplay(sliceDataCompute);
  }, [page, rowsPerPage, dataCompute]);

  useEffect(() => {
    setPage(1);
  }, [repositoryDataObject.filters]);

  return (
    <Col className="build-content-cls">
      {repositoryDataObject.repositoryData.length !== 0 && !repositoryDataObject.loading && (
        <Row className="repository-header-analysis-cls">
          <p className="repository-search-title">Program Analysis Results</p>
        </Row>
      )}
      <div className="build-list-container" style={repositoryDataObject.repositoryData.length !== 0 && !repositoryDataObject.loading ? { overflowY: 'scroll' } : {}}>
        {repositoryDataObject.loading && (
          <div className="spinner-placing">
            <Spinner />
          </div>
        )}
        {!repositoryDataObject.loading && repositoryDataObject.repositoryData.length === 0 && (
          <div className="no-data-component-cls no-data warning spinner-placing">
            <MpdEmptyDataComponent iconName={iconName} title={title} subTitle={subTitle} />
          </div>
        )}
        {dataDisplay}
      </div>
    </Col>
  );
};

export default BuildListContainer;
