import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API } from 'aws-amplify';

import { CustomThunkAction, APIRequestObj, MyKnownError } from './thunkTypes';
// import { HttpSuccess } from '../customHooks/apiTypes';
import * as configs from '../../config';
import { secureLS } from '../localStorageUtil/lsHelper';
import { PROGRAM_ANALYSIS_SLICE } from '../../models/programAnalysis/constants';
import { TASKS_SLICE } from '../../models/taskReportModel/constants';

// Description - createCustomAsyncThunk is a wrapper for createAsyncThunk function with inbuilt ability to make API request

/* createAsyncThunk
  *  It generates promise lifecycle action types based on the action type prefix that you pass
  *  Returns a thunk action creator that will run the promise callback
  *  Dispatch the lifecycle actions based on the returned promise
  *  Documentation - https://redux-toolkit.js.org/api/createAsyncThunk
*/

// Example
/*
    customAction: {
        sliceName: 'users',                     // MANDATORY
        actionType: 'addUsers'                  // MANDATORY
    }
*/
/*
     apiRequestObj: {
        method: 'get | post | del | put | del', // MANDATORY
        path: '/Users',                         // MANDATORY
        queryStringParameters: {                // OPTIONAL
            name: 'param'
        }
        body: {                                 // OPTIONAL
            key: value
        },
        header: {                               // OPTIONAL
          operator: ''
        }
    }
*/
/* eslint-disable @typescript-eslint/no-explicit-any */
const createCustomAsyncThunk = (customAction: CustomThunkAction, apiRequestObj: APIRequestObj) => createAsyncThunk<any, any, { rejectValue: MyKnownError }>(
  `${customAction.sliceName}/${customAction.actionType}`,
  async (arg: any, thunkApi) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user?.signInUserSession?.idToken?.jwtToken;
      const {
        path, method, body, queryStringParameters, header,
      } = apiRequestObj;
      const operator = header?.operator || secureLS.get('operator');
      const maintenanceProgram = header?.maintenanceProgram || secureLS.get('ac_program');
      const request = {
        headers: {
          Authorization: token,
          Operator: operator || 'None',
          'Data-Source': 'BACKBONE',
          'Maintenance-Program': maintenanceProgram || 'None',
        },
        ...(queryStringParameters && { queryStringParameters }),
        ...(body && { body }),
      };

      const defaults = configs.default();
      const response = await API[method](defaults.API.endpoints[0].name, path, request);
      // Uncomment once the response has been structured
      // if (!HttpSuccess.includes(response.statusCode)) {
      //   Return the known error for future handling
      //   return thunkApi.rejectWithValue(response as MyKnownError);
      // }
      return response;
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
      // const serverErrorMessage = error?.response?.data;
      // if (serverErrorMessage?.error) {
      //   const randomId = Date.now();
      //   thunkApi.dispatch(createToast({
      //     id: `${randomId}`, cssClass: 'repo-tost-cls', variant: 'error', title: `${serverErrorMessage.error.module}`, content: [`${serverErrorMessage.error.error_code} - ${serverErrorMessage.message}`],
      //   }));
      // }
      if ([PROGRAM_ANALYSIS_SLICE, TASKS_SLICE].includes(customAction.sliceName)) return thunkApi.rejectWithValue({ error: JSON.parse(error.request.response).message, httpStatus: error.request.status } as MyKnownError);
      // if (customAction.sliceName.includes(PROGRAM_ANALYSIS_SLICE)) return thunkApi.rejectWithValue({ error: JSON.parse(error.request.response).message, httpStatus: error.request.status } as MyKnownError);
      /* Added error log to view the reason for api failure in browser */
      return thunkApi.rejectWithValue(error.message as MyKnownError);
    }
  },
);

export default createCustomAsyncThunk;
