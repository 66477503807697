import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import {
  Card,
  CardContent,
  CardTitle,
  Toggle,
} from '@airbus/components-react';
import { useParams } from 'react-router-dom';
import { unscheduleDashBoardQLinks } from '../../__mocks__/TaskReportAnalysis/QuickLinks';
import QuickLinks from '../Shared/QuickLinks/QuickLinks';
import CardComponent from '../Shared/Card/CardComponent';
import { LOCALES } from '../../assets/locale';
import './UnscheduledEvents.scss';
import { fetchOIInfos, fetchOITaskInfos, fetchTableInfos } from '../../models/unscheduledEventsModel/unscheduledEventAsyncThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import {
  createGraphOiVsOtherData, createGraphsReportAvgOI, createHeaderCardArray, filterTable, formatTableArray, oldCreateHeaderCardArray,
} from './utils';
import {
  AC_TYPE,
  ATA_PERFORMED,
  ID_AIRCRAFT,
  OCCURENCE_DATE,
  OI_DESCRIPTION,
  OI_DURATION,
  OI_DURATION_NAMING,
  OI_RESOLUTION_DESCRIPTION,
  OI_TYPE,
  DATASET_DETAILS_ATA2D,
  DATASET_DETAILS_TADA,
  DATASET_ATA_2D_AGGREGATES,
  DATASET_TADA_AGGREGATES,
} from '../../models/unscheduledEventsModel/constants';
import GraphCard from './GraphCard';
import GenericTable from '../Shared/GenericTable/GenericTable';
import DataTableHeader from '../Shared/DataTable/DataTableHeader';
import DataTableCell from '../Shared/DataTable/DataTableCell';
import { generateHidableColumns, getTableColumns, TableColumnState } from '../MpdTaskTable/mpdTableStructureUtils';
import {
  deleteTDFilterGroup, emptyTableFiltersGroup, updateEnhanceToggle, updateTableData, updateTDFilterGroup, updateTDSearchOptions, updateTDSearchPair, clearOIData, clearOIDataTask,
} from '../../models/unscheduledEventsModel/unscheduledEventSlice';
import { DEFAULT_TABLE_DATA } from '../TaskReportAnalysis/constants';
import SkywiseCrown from '../Shared/SkywiseCrown/SkywiseCrown';
import TooltipComponent from '../Shared/MpdTooltip/mpdTooltip';
import { featureSwitchConfig, featureToggleConfig } from '../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { appContext } from '../../utils/context/userContext';

const unscheduledEventsTableCols = [
  {
    Header: 'A/C Type',
    accessor: AC_TYPE,
    width: 40,
    id: AC_TYPE,
    maxWidth: 30,
  },
  {
    Header: 'MSN',
    accessor: ID_AIRCRAFT,
    width: 20,
    id: ID_AIRCRAFT,
  },
  {
    Header: 'Date',
    accessor: OCCURENCE_DATE,
    width: 20,
    id: OCCURENCE_DATE,
  },
  {
    Header: 'ATA Perf',
    accessor: ATA_PERFORMED,
    width: 20,
    id: ATA_PERFORMED,
  },
  {
    Header: 'Description',
    accessor: OI_DESCRIPTION,
    width: 160,
    id: OI_DESCRIPTION,
  },
  {
    Header: 'Work Performed',
    accessor: OI_RESOLUTION_DESCRIPTION,
    width: 160,
    id: OI_RESOLUTION_DESCRIPTION,
  },
  {
    Header: 'Ecam Warning',
    accessor: OI_TYPE,
    width: 70,
    id: OI_TYPE,
  },
  {
    Header: OI_DURATION_NAMING,
    accessor: OI_DURATION,
    width: 30,
    id: OI_DURATION,
  },
];

const lsdrTasks = ['LUB', 'SVC', 'DIS', 'RST'];

const generateTableColumns: (onClickHandler: Dispatch<SetStateAction<string>>) => mpdTableColsType[] = (onClickHandler) => unscheduledEventsTableCols.map((header) => ({
  Header: <DataTableHeader title={header.Header} id={header.id} hideMode={false} onClickHandler={onClickHandler} />,
  ...(header.maxWidth && { maxWidth: header.maxWidth }),
  title: header.Header,
  accessor: header.accessor,
  id: header.id,
  disableSortBy: false,
  width: header.width,
  Cell: ({ row }: mpdTask) => <DataTableCell maxLength={50} maxLines={1} cellValue={`${row.values[header.accessor]}`} />,
}));

interface QueryParams {
  pageIndex: number;
  pageSize: number;
  sortBy: [];
}

export default function UnscheduledEvents() {
  const { filterId, taskNumber } = useParams();
  const dispatch = useAppDispatch();
  const { userType } = useContext(appContext);
  const unscheduledEventData: unscheduledEventState = useAppSelector((state: RootState) => state.unscheduledEvent);
  const taskCode = useAppSelector((state: RootState) => state.task?.taskInfo?.mpd_task_code);
  const [headerCardArray, setHeaderCardArray] = useState([]);
  const [graphReportPerFleetWide, setGraphReportPerFleetWide] = useState<object | undefined>(undefined);
  const [graphOiVsFindingRate, setGraphOiVsFindingRate] = useState<object | undefined>(undefined);
  const [graphOiVsRSI, setGraphOiVsRSI] = useState<object | undefined>(undefined);
  const [oiTableData, setOiTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [limit, setLimit] = useState(DEFAULT_TABLE_DATA.limit);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [page, setPage] = useState(1);
  const noSearchKeySizeLimitColumnsArray = [OI_DURATION_NAMING];

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [dimensions]);

  const onPaginationChange = (params: QueryParams) => {
    setLimit(params.pageSize);
    setPage(params.pageIndex + 1);
  };

  const updateFilterColumnName = (columnName: string) => {
    dispatch(updateTDSearchPair({ columnName, columnInput: '' }));
    dispatch(updateTDSearchOptions([]));
  };

  const getTableInfo = () => {
    dispatch(updateTableData({ data: [], loading: false }));
    if (unscheduledEventData.enhanceState) {
      dispatch(fetchTableInfos(filterId, DATASET_DETAILS_TADA, 'mpd_task_reference', taskNumber)());
    } else {
      dispatch(fetchTableInfos(filterId, DATASET_DETAILS_ATA2D, 'ata_2d', taskNumber?.slice(0, 2))());
    }
  };

  /**
   * Fetches the aggregated OI data from ATA2D/TADA datasets depending on the value of enhanced state.
   */
  const getOIInfo = () => {
    dispatch(clearOIData());
    if (unscheduledEventData.enhanceState) {
      dispatch(fetchOIInfos(filterId, DATASET_TADA_AGGREGATES, 'mpd_task_reference', taskNumber)());
    } else {
      dispatch(fetchOIInfos(filterId, DATASET_ATA_2D_AGGREGATES, 'ata_2d', taskNumber?.slice(0, 2))());
    }
  };

  const updateFilterColumnValue = (value: string) => dispatch(updateTDSearchPair({ columnInput: unscheduledEventData.tableFilters.searchPairData.columnName === OI_DURATION ? Number(value) : value, columnName: unscheduledEventData.tableFilters.searchPairData.columnName }));

  const updateFilterGroup = () => dispatch(updateTDFilterGroup([unscheduledEventData.tableFilters.searchPairData]));

  const deleteFromFilterGroup = (deletePair: searchPair[]) => dispatch(deleteTDFilterGroup(deletePair));

  const fetchTDFilterValues = (userInput: string) => {
    const newArr: tableFetchData[] = [];
    const column = unscheduledEventData.tableFilters.searchPairData.columnName;
    unscheduledEventData.tableData.forEach((item) => {
      const conditionToFilter = item?.[column]?.toString().toUpperCase().indexOf(userInput.toUpperCase()) >= 0 // Is userInputValue included in data (skip if item or item[column] is null)
          && !newArr.find((itemBis) => itemBis[column] === item[column]); // Check if filter is already applied
      /* istanbul ignore else */
      if (conditionToFilter) newArr.push({ [column]: item[column] });
    });
    dispatch(updateTDSearchOptions(newArr));
  };

  useEffect(() => {
    dispatch(clearOIDataTask());
    dispatch(fetchOITaskInfos(filterId, taskNumber)());
    getOIInfo();
    getTableInfo();
    return () => {
      dispatch(updateTableData({ data: [], loading: false }));
      dispatch(emptyTableFiltersGroup());
      dispatch(clearOIData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unscheduledEventData.enhanceState]);

  useEffect(() => {
    if (unscheduledEventData.oiData.length !== 0 && unscheduledEventData.oiTaskData.length !== 0) {
      const currentOI = unscheduledEventData.oiData[0];
      const currentTaskOI = unscheduledEventData.oiTaskData[0];
      const graphVsData = createGraphOiVsOtherData(currentOI, currentTaskOI, !lsdrTasks.find((elem) => taskCode === elem), unscheduledEventData.enhanceState);
      const reportAvgOIData = createGraphsReportAvgOI(currentOI);

      const oldCreateHeaderCardArrayPayload = oldCreateHeaderCardArray(currentOI);
      const newCreateHeaderCardArrayPayload = createHeaderCardArray(currentOI, unscheduledEventData.enhanceState);
      const createHeaderCardArrayToggled = featureSwitchConfig(
        { name: 'EnhancedAnalysis', userType },
        newCreateHeaderCardArrayPayload,
        oldCreateHeaderCardArrayPayload,
      );

      setHeaderCardArray(createHeaderCardArrayToggled);
      setGraphReportPerFleetWide(reportAvgOIData);
      setGraphOiVsFindingRate(graphVsData.findingRate);
      setGraphOiVsRSI(graphVsData.rsi);
    } else {
      setHeaderCardArray([]);
      setGraphReportPerFleetWide(undefined);
      setGraphOiVsFindingRate(undefined);
      setGraphOiVsRSI(undefined);
    }

    if (unscheduledEventData.tableData.length !== 0) {
      setOiTableData(formatTableArray(filterTable(unscheduledEventData.tableData, unscheduledEventData.tableFilters.filterGroup)));
    } else {
      setOiTableData([]);
    }
  }, [taskCode, unscheduledEventData, userType]);

  useEffect(() => {
    setDataDisplay(oiTableData.slice((page - 1) * limit, page * limit));
  }, [oiTableData, page, limit]);

  const setEnhanceStateHandler = () => {
    dispatch(updateEnhanceToggle());
  };

  const tableTitle = (
    <div className="unschedule-table-title">
      <span><b>{LOCALES.unschedule_event_table}</b></span>
      <span>{unscheduledEventData.enhanceState && <SkywiseCrown />}</span>
    </div>
  );

  const enhancedAnalysisComponent = (
    <div className="enhanced-cal">
      <SkywiseCrown />
      Enhanced Analysis
      <div style={{ marginTop: '3px' }}>
        <TooltipComponent title="" data={LOCALES.enhance_Info} placement="left" />
      </div>
      <Toggle className="enhanced-toggle" aria-label="enhance-toggle" checked={unscheduledEventData.enhanceState} onClick={setEnhanceStateHandler} />
    </div>
  );
  const enhancedAnalysisToggled = featureToggleConfig({ name: 'EnhancedAnalysis', userType }, enhancedAnalysisComponent);

  const oiVsRsiGraphComponent = (
    !unscheduledEventData.enhanceState ? (
      <Card className="graph-card">
        <CardTitle className="card-title">
          {LOCALES.rsi_graph_title}
        </CardTitle>
        <CardContent className="card-content">
          <div className="enhanced-analysis-off-text">
            Data available for enhanced analysis
          </div>
        </CardContent>
      </Card>
    ) : (
      <GraphCard graph={graphOiVsRSI} loading={unscheduledEventData.loading} title={LOCALES.rsi_graph_title} enhancedAnalysis />
    )
  );

  const oldOiVsRsiGraphComponent = (
    <GraphCard graph={graphOiVsRSI} loading={unscheduledEventData.loading} title={LOCALES.rsi_graph_title} />
  );

  const oiVsRsiGraphComponentToggled = featureSwitchConfig(
    { name: 'EnhancedAnalysis', userType },
    oiVsRsiGraphComponent,
    oldOiVsRsiGraphComponent,
  );

  return (
    <>
      {enhancedAnalysisToggled}
      <CardComponent
        cardHeader={[]}
        cardBody={headerCardArray}
        noOfElementsPerRow={4}
        config={{
          className: 'card-full-screen',
        }}
        loading={unscheduledEventData.loading}
      />
      <QuickLinks quickLinks={unscheduleDashBoardQLinks.quickLinks} title={unscheduleDashBoardQLinks.title} />
      {loading ? (
        <div />
      ) : (
        <div className="row-graphs">
          <div className="graph-size">
            <GraphCard graph={graphReportPerFleetWide} loading={unscheduledEventData.loading} title="Comparison of OI reported per A/C between fleet wide and operator" enhancedAnalysis={unscheduledEventData.enhanceState} />
          </div>
        </div>
      )}
      {loading ? (
        <div />
      ) : (
        <div className="row-graphs">
          <div className="graph-size">
            <GraphCard graph={graphOiVsFindingRate} loading={unscheduledEventData.loading} title="OI Reported vs Finding Rate per A/C age" enhancedAnalysis={unscheduledEventData.enhanceState} />
          </div>
          <div className="graph-size">
            {oiVsRsiGraphComponentToggled}
          </div>
        </div>
      )}
      <GenericTable
        tableTitle={tableTitle}
        tableCols={unscheduledEventsTableCols}
        tableData={dataDisplay}
        totalDataCount={oiTableData.length}
        onPaginationChangeCallback={onPaginationChange}
        Loading={unscheduledEventData.loading}
        updateColumnFilter={updateFilterColumnName}
        updateColumnFilterValue={updateFilterColumnValue}
        updateFilterGroup={updateFilterGroup}
        deleteFromFilterGroup={deleteFromFilterGroup}
        fetchColumnFilterValues={fetchTDFilterValues}
        searchPair={unscheduledEventData.tableFilters.searchPairData}
        columnFilter={unscheduledEventData.tableFilters.searchOptions}
        filterGroup={unscheduledEventData.tableFilters.filterGroup}
        generateTableColumns={generateTableColumns}
        tableColumnState={TableColumnState}
        getTableColumns={getTableColumns}
        generateHidableColumns={generateHidableColumns}
        cssClass="unsched-events-table"
        isDataDynamic
        noSearchKeySizeLimitColumns={noSearchKeySizeLimitColumnsArray}
        filterChipPositionDetached
      />
    </>
  );
}
