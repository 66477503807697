export const LOCALES = {
  APP_NAME: 'S. Maintenance Performance',
  OVERVIEW_TEXT:
    'S. Maintenance Performance Applications',
  Raw_Task_Report: 'Task Report',
  Enrich_Task_Report: 'Enrich Task Report',
  Table_Settings_Dialog: 'Select rows to be displayed per page',
  finding_type_per_actual_interval_title: 'Findings types and Finding rate per actual interval',
  finding_type_and_finding_rate_per: 'Findings types and Finding rate per cumulative interval',
  task_report_finding_rate_msn: 'Number of Task Reports and associated Finding rate by MSN',
  unschedule_event_table: 'Unscheduled Events',
  new_disclaimer_general: 'Disclaimer : This assessment is not applicable for systems safety-related tasks because cost is not an effectiveness criterion for such tasks.'
  + ' For Zonal and Structure tasks only the cost for scheduled event is indicated. The cost of unscheduled is based on OIs potentially linked to MPD task using machine learning algorithm. Therefore, the cost value for unscheduled is only an estimation.',
  disclaimer_general: 'Disclaimer : This assessment is not applicable for systems safety-related tasks because cost is not an effectiveness criterion for such tasks. ',
  disclaimer_str_znl: 'For Zonal and Structure tasks only the cost for scheduled event is indicated.',
  operator_finding_type: 'Operator number of findings vs Finding Type',
  fleetwide_finding_type: 'Fleet-wide number of findings vs Finding Type',
  operator_task_reports_per_year: 'Operator Task Reports received per year',
  finding_rate_by_geo_region: 'Finding rate by geo region',
  enhance_Info: 'Raw data analysis is based on OI reported at ATA 2D chapter level. For analysis at task level please activate the Enhanced analysis that is based on pre-enriched data using Artificial Intelligence assessing the relevance of unscheduled events',
  enhance_Info_taskreport_toggle: 'Enhanced Analysis is based on pre-enriched data using Artificial Intelligence assessing the relevance of task reports.',
  rsi_graph_title: 'OI Reported vs RSI per A/C age',
  findingrate_graph_title: 'OI Reported vs Finding Rate per A/C age',
  fleetwide_graph_title: 'Comparison of OI reported per A/C between fleet wide and operator',
};
