import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import getConfig from '../../config';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';

const config = getConfig();

const { logoutUrl } = config.Auth.oauth;

const SignOut = () => {
  useEffect(() => {
    secureLS.remove('operator');
    secureLS.remove('ac_program');
    secureLS.remove('architecture');
    Auth.signOut()
      .then(() => window.location.assign(logoutUrl))
      .catch(() => window.location.assign(logoutUrl));
  }, []);
  return null;
};

export default SignOut;
