/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from '@airbus/components-react';
import { isEqual } from 'lodash';

import GenericTable from '../../Shared/GenericTable/GenericTable';
import { RawTaskReportCols, noFilterSize } from '../../../__mocks__/UnscheduledEvents/TaskReportsTable';
import { getTypeCastedToStringRawTaskReport } from '../../../models/taskReportModel/taskReportUtils';
import {
  fetchPrecomputedRawTaskReports,
  fetchPrecomputedRawTaskReportsColumnSearch,
} from '../../../models/taskReportModel/taskreportAsyncThunks';
import {
  clearRawTaskReportfilterGroup,
  updatePrecomputeRawTaskReportfilter,
  updatePrecomputeRawTaskReportfilterGroup,
  updatePrecomputeRawTaskReportfilterValue,
  deletePrecomputeRawTaskReportfilterGroup,
  updatePrecomputeTaskReportPerformanceTuning,
} from '../../../models/taskReportModel/taskReportSlice';
import { getTableColumns, generateHidableColumns } from '../../MpdTaskTable/mpdTableStructureUtils';
import { generateTableColumns, TableColumnState } from '../TRAnalysisUtils';
import { LOCALES } from '../../../assets/locale';

import { RootState, store } from '../../../store/store';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { DEFAULT_TABLE_DATA } from '../constants';
import NoData from '../../Shared/Charts/NoData';

interface QueryParams {
  pageIndex: number;
  pageSize: number;
  sortBy: [];
}

interface TaskReportTableProps {
  operator: string;
}

export const updateTaskReportColumnValue = (value: string) => store.dispatch(updatePrecomputeRawTaskReportfilterValue(value));

export const deleteFromTaskReportFilterGroup = (deletePair: searchPair[]) => store.dispatch(deletePrecomputeRawTaskReportfilterGroup(deletePair));

const TaskReportTable = (props: TaskReportTableProps): JSX.Element => {
  const { operator } = props;
  const { filterId, taskNumber } = useParams();
  const dispatch = useAppDispatch();
  const [selectedTaskTableTab] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_TABLE_DATA.offset);
  const [limit, setLimit] = useState(DEFAULT_TABLE_DATA.limit);
  const { performance, precomputerawTaskReports } = useAppSelector((state: RootState) => state.task);
  const [isGenericTableLoading, setIsGenericTableLoading] = useState(true);
  const [isStatusFailed, setStatusFailed] = useState(false);

  const rawTaskReportsData = useMemo(() => getTypeCastedToStringRawTaskReport(precomputerawTaskReports.data), [precomputerawTaskReports.data]);

  const paginationOnChange = (params: QueryParams) => {
    const newOffset = Math.round(params.pageIndex * params.pageSize);
    setOffset(newOffset);
    setLimit(params.pageSize);
  };

  useEffect(() => {
    const { filterId: prevFilterId, taskNumber: prevTaskNumber, table } = performance;
    const { limit: prevLimit, offset: prevOffset, filterGroup: prevFilterGroup } = table;
    /* fetch raw task reports data only if filter Id, task number,limit, offset and filter group changes */
    if (prevFilterId !== filterId || prevTaskNumber !== taskNumber || prevLimit !== limit || prevOffset !== offset || !isEqual(prevFilterGroup, precomputerawTaskReports.filterGroup)) {
      dispatch(updatePrecomputeTaskReportPerformanceTuning({ filterId, taskNumber, table: { limit, offset, filterGroup: precomputerawTaskReports.filterGroup } }));
    }
  }, [filterId, taskNumber, operator, limit, offset, precomputerawTaskReports.filterGroup]);

  useEffect(() => {
    dispatch(fetchPrecomputedRawTaskReports({
      filterId, taskNumber, offset, limit,
    })());
  }, [filterId, taskNumber]);

  useEffect(() => {
    /* Start Raw Task Report Sync */
    setIsGenericTableLoading(precomputerawTaskReports.loading);
    if (precomputerawTaskReports.error === 'failed') {
      setStatusFailed(true);
    }
  }, [precomputerawTaskReports.loading, precomputerawTaskReports.error]);

  useEffect(() => {
    dispatch(fetchPrecomputedRawTaskReports({
      taskNumber, filterId, limit, offset, searchColumn: precomputerawTaskReports.filterGroup,
    })());
  }, [offset, precomputerawTaskReports.filterGroup, limit]);

  useEffect(() => {
    // clear already selected filters when component unmounts
    return () => {
      dispatch(clearRawTaskReportfilterGroup());
    };
  }, []);

  const updateTaskReportColumnName = (columnName: string) => dispatch(updatePrecomputeRawTaskReportfilter(columnName));

  const updateTaskReportFilterGroup = () => dispatch(updatePrecomputeRawTaskReportfilterGroup([precomputerawTaskReports?.searchPairData]));

  const fetchTaskReportFilterValues = (userInput: string) => dispatch(
    fetchPrecomputedRawTaskReportsColumnSearch({
      filterId,
      taskNumber,
      searchColumn: {
        columnName: precomputerawTaskReports?.searchPairData?.columnName,
        columnInput: userInput,
      },
    })(),
  );

  const displayTableComponent = () => {
    if (isStatusFailed) {
      return (
        <div style={{ backgroundColor: '#FFFFFF' }}>
          <NoData type="error" message="Error occured . Please try after sometime. " customClassName="api-fetching-error" />
        </div>
      );
    }
    return (
      <GenericTable
        tableCols={RawTaskReportCols}
        tableData={rawTaskReportsData}
        totalDataCount={precomputerawTaskReports.totalCount}
        onPaginationChangeCallback={paginationOnChange}
        Loading={isGenericTableLoading}
        updateColumnFilter={updateTaskReportColumnName}
        updateColumnFilterValue={updateTaskReportColumnValue}
        updateFilterGroup={updateTaskReportFilterGroup}
        deleteFromFilterGroup={deleteFromTaskReportFilterGroup}
        fetchColumnFilterValues={fetchTaskReportFilterValues}
        searchPair={precomputerawTaskReports.searchPairData}
        columnFilter={precomputerawTaskReports.searchOptions}
        filterGroup={precomputerawTaskReports.filterGroup}
        generateTableColumns={generateTableColumns}
        tableColumnState={TableColumnState}
        getTableColumns={getTableColumns}
        generateHidableColumns={generateHidableColumns}
        isDataDynamic
        noSearchKeySizeLimitColumns={noFilterSize}
        filterChipPositionDetached
      />
    );
  };

  return (
    <div className="task-report-table">
      <Tabs
        style={{ margin: '15px 0 0 0' }}
        aria-label="task_report_table"
        value={selectedTaskTableTab}
      >
        <Tab>{LOCALES.Raw_Task_Report}</Tab>
      </Tabs>
      {/* Hiding the tables on tab change instead of removing from dom for preventing additional api call */}
      {/* <div style={{ display: selectedTaskTableTab ? 'none' : 'block' }}> */}
      <div style={{ display: 'block' }}>
        { displayTableComponent() }
      </div>
    </div>
  );
};

export default TaskReportTable;
