import { createSlice } from '@reduxjs/toolkit';
import { createDeltaSocTableView, createDeltaSocTableViewFilter } from './deltaViewSocAsyncThunk';
import {
  clearAllDeltaSocTableViewDataReducer, createDeltaSocTableViewFilterFullfilledReducer,
  createDeltaSocTableViewFullfilledReducer, createDeltaSocTableViewPendingReducer,
  createDeltaSocTableViewRejectedReducer,
} from './deltaViewSocReducers';

export const initialState: deltaViewSocData = {
  socTableData: {
    filterData: [],
    headers: [],
    records: [],
    colorCode: [],
    totalHits: 0,
  },
  socDeltaViewDataLoading: false,
  socDeltaViewFilterData: {
    column_name: '',
    column_value: [],
  },
  error: false,
};

const deltaViewSoctSlice = createSlice({
  name: 'deltaViewSoc',
  initialState,
  reducers: {
    clearAllDeltaSocTableViewData: clearAllDeltaSocTableViewDataReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(createDeltaSocTableView().fulfilled, createDeltaSocTableViewFullfilledReducer);
    builder.addCase(createDeltaSocTableView().rejected, createDeltaSocTableViewRejectedReducer);
    builder.addCase(createDeltaSocTableView().pending, createDeltaSocTableViewPendingReducer);
    builder.addCase(createDeltaSocTableViewFilter().fulfilled, createDeltaSocTableViewFilterFullfilledReducer);
  },
});

export const { clearAllDeltaSocTableViewData } = deltaViewSoctSlice.actions;
export default deltaViewSoctSlice;
