import React from 'react';
import {
  Button,
  Col,
  Row,
} from '@airbus/components-react';
import { useNavigate } from 'react-router-dom';
import './MpdRepositoryEmtlDeltaCard.scss';
import MpdRepositoryDownloadMenu from '../MpdRepositoryDownloadMenu/MpdRepositoryDownloadMenu';
import MpdRepositoryCardDelete from '../MpdRepository/ProgCustomization/MpdRepositoryCardDelete/MpdRepositoryCardDelete';
import { setFilterPanelOpen } from '../../models/mpdRepositoryModel/mpdRepositorySlice';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';

type mpdRepoEmtlDeltaListProps = {
  createAt: string;
  comparedFileName: string;
  inputFileName?: string;
  refFileName?: string;
  hashKey?: string
  fileKey: string;
};

const MpdRepositoryEmtlDeltaCard = (props: mpdRepoEmtlDeltaListProps) => {
  const {
    createAt, inputFileName, refFileName, fileKey, comparedFileName, hashKey,
  } = props;
  const {
    RepositoryButtonDisabled: btnsDisabled,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const excelType = ['.xlsx'];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div className="mpd-repository-emtl-delta-card" aria-label="mpdRepositoryEmtlDeltaCard">
      <Row>
        <Col className="emtl-delta-card-detail-cls">
          <p id="emtl-detla-file-title">
            <b>{inputFileName}</b>
            <span>|</span>
            <b>{refFileName}</b>
          </p>
          <p className="timestamp-cls">
            {`Created on ${new Date(createAt)}`}
          </p>
        </Col>
        <Col className="emtl-delta-card-action-cls">
          <Button
            aria-label="mpdEMTLDeltaViewTask"
            variant="primary"
            className="action-btn-cls"
            onClick={
              () => {
                navigate(`/repository/emtl/view/${hashKey}`);
                dispatch(setFilterPanelOpen(false));
              }
            }
            disabled={btnsDisabled === comparedFileName}
          >
            View
          </Button>
          <MpdRepositoryDownloadMenu
            fileTypes={excelType}
            fileKey={fileKey}
            fileName={comparedFileName}
          />
          <MpdRepositoryCardDelete fileName={`${inputFileName}\u00a0\u00a0|\u00a0\u00a0${refFileName}`} fileKey={fileKey} inputFileName={inputFileName} refFileName={refFileName} comparedFileName={comparedFileName} />
        </Col>
      </Row>
    </div>
  );
};

MpdRepositoryEmtlDeltaCard.defaultProps = {
  inputFileName: '',
  refFileName: '',
  hashKey: '',
};

export default MpdRepositoryEmtlDeltaCard;
