/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PRECOMPUTED_API_PATH, UE_PRECOMP_OI_DATA_FILTER, UNSCHEDULED_EVENT_PRECOMP_SLICE, UNSCHEDULED_FINDING_RATE_SLICE,
} from './constants';
import { Methods } from '../../utils/customHooks/apiTypes';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';

export const fetchOiHeaderCardData: any = (filterId: string, primaryScope: string, secondaryScope: string, ata2d: number) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: UE_PRECOMP_OI_DATA_FILTER,
  };
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=${primaryScope}&secondary_scope=${secondaryScope}&ata_2d=${ata2d}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchFleetwideGraphData: any = (filterId: string, ata2d: number) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: 'fetchFleetwideGraphData',
  };
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=oi_graph&ata_2d=${ata2d}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTableData: any = (filterId: string, ata2d: number, pageNumber = 1, pageSize = 10, filterGroups:{columnName:string, columnInput:string}[] = []) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: 'fetchTableData',
  };

  // NM-TO-BE-CHANGED: Remove below comment after table filter API is implemented
  /* istanbul ignore next */
  const filters = filterGroups.map((filterGroup) => `&${filterGroup.columnName}=${filterGroup.columnInput}`).join('');
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=table&ata_2d=${ata2d}&page_number=${pageNumber}&page_size=${pageSize}${filters}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchUnscheduleFindingRateData: any = (filterId: string, ata2d: number, taskNumber: string) => {
  const customAction = {
    sliceName: UNSCHEDULED_FINDING_RATE_SLICE,
    actionType: 'fetchFindingRateGraphData',
  };

  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=oi_finding&ata_2d=${ata2d}&task_number=${taskNumber}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTableFilterData: any = (filterId: string, ata2d: number, columnName: string, columnValue: string, filterGroups:{columnName:string, columnInput:string}[] = []) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: 'fetchTableFilterData',
  };

  /* istanbul ignore next */
  const filters = filterGroups.map((filterGroup) => `&${filterGroup.columnName}=${filterGroup.columnInput}`).join('');
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=table_filter&ata_2d=${ata2d}&column_name=${columnName}&column_value=${columnValue}${filters}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
