import React from 'react';
import {
  Card, CardHeader, CardContent, Row, Col, Container, Toggle, ProgressBar, Tooltip, Placement, ButtonGroup, Button,
} from '@airbus/components-react';

import NoData from '../Charts/NoData';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import './CardComponent.scss';
import SkywiseCrown from '../SkywiseCrown/SkywiseCrown';

interface CardHeader {
  title: string;
  type: string;
  align: string;
  action?: string;
  isPremium?: boolean;
  className?: string;
}

export type ProgressBarStatus = 'success' | 'error' | 'warning' | undefined;

export declare type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface CardBody {
  label: string;
  value: string;
  type: string;
  isPremium?: boolean;
  className?: string;
  status?: ProgressBarStatus;
  tooltipText?: Placement | string;
  tooltipPlacement?: string;
  gridSize?: GridSize;
  alignmentClass?: string;
  minWidthClass?: string;
  groupButtonOption?: [
    {
      label: string;
      pressed: boolean;
      icon?: boolean;
      name?: string;
    },
  ];
}

interface Config {
  className: string;
}

interface CardInfoArray {
  cardHeader: CardHeader[];
  cardBody: CardBody[];
  noOfElementsPerRow: number;
  config: Config;
  loading?: boolean;
  status?: string;
}

interface CardSize {
  [name: string]: number;
}

const CARD_SIZE_INDEX_MAP = {
  xl: 0,
  md: 1,
  sm: 2,
  xxs: 3,
};

const cardSize: GridSize[] = new Array(4).fill('auto');

const CARD_PREDEFINED_SIZE: CardSize = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
};

const CARD_SIZE_MAP = {
  [CARD_PREDEFINED_SIZE.ONE]: () => {
    cardSize[CARD_SIZE_INDEX_MAP.xl] = 'auto';
    cardSize[CARD_SIZE_INDEX_MAP.md] = 'auto';
    cardSize[CARD_SIZE_INDEX_MAP.sm] = 'auto';
    cardSize[CARD_SIZE_INDEX_MAP.xxs] = 'auto';
  },
  [CARD_PREDEFINED_SIZE.THREE]: () => {
    cardSize[CARD_SIZE_INDEX_MAP.xl] = 4;
    cardSize[CARD_SIZE_INDEX_MAP.md] = 4;
    cardSize[CARD_SIZE_INDEX_MAP.sm] = 6;
    cardSize[CARD_SIZE_INDEX_MAP.xxs] = 6;
  },
  [CARD_PREDEFINED_SIZE.FOUR]: () => {
    cardSize[CARD_SIZE_INDEX_MAP.xl] = 3;
    cardSize[CARD_SIZE_INDEX_MAP.md] = 3;
    cardSize[CARD_SIZE_INDEX_MAP.sm] = 6;
    cardSize[CARD_SIZE_INDEX_MAP.xxs] = 'auto';
  },
};

export default function CardComponent(props: CardInfoArray): JSX.Element {
  const {
    cardHeader, cardBody, noOfElementsPerRow, config, loading, status,
  } = props;
  const totalCardsPerRow: number = noOfElementsPerRow;
  CARD_SIZE_MAP[totalCardsPerRow]();

  const [cardBodyXL, cardBodyMD, cardBodySM, cardHeaderXXS] = cardSize;

  const skywiseCrown = (
    <SkywiseCrown />
  );

  const displayHeader = (card: CardHeader): JSX.Element => {
    if (card.type === 'Label') {
      return (
        <Col xxs={cardHeaderXXS} className="card-header-left" key={card.title}>
          <span>{card.title}</span>
        </Col>
      );
    }

    if (card.type === 'Toggle') {
      return (
        <Col xxs={cardHeaderXXS}>
          <div className="card-header-right" key={card.title}>
            {!card.isPremium && skywiseCrown}
            <span>{card.title}</span>
            <Toggle className="card-premium-toggle" size="small" disabled />
          </div>
        </Col>
      );
    }
    return <div key={card.title} />;
  };

  const displayBodyInfo = (cardbody: CardBody): JSX.Element => {
    const newCardBodySM = cardbody?.gridSize || cardBodySM;
    const newCardBodyMD = cardbody?.gridSize || cardBodyMD;
    const newCardBodyXL = cardbody?.gridSize || cardBodyXL;
    if (cardbody.type === 'Label') {
      return (
        <Col className={`col ${cardbody.alignmentClass}`} sm={newCardBodySM} md={newCardBodyMD} xl={newCardBodyXL} key={cardbody.label} style={{ display: 'flex' }}>
          {cardbody.isPremium && skywiseCrown}
          <span className={cardbody.minWidthClass}>{cardbody.label}</span>
          <span> : </span>
          <span className="card-body-value">
            <b className={cardbody.className}>{cardbody.value}</b>
          </span>
        </Col>
      );
    }
    if (cardbody.type === 'ButtonGroup') {
      return (
        <Col className="col">
          <ButtonGroup className="card-premium-buttongroup" size="small">
            {cardbody.groupButtonOption ? (
              cardbody.groupButtonOption.map((item) => {
                return (
                  <Button className={item.name} pressed={item.pressed} icon={item.icon ? skywiseCrown : false} size="small">
                    {item.label}
                  </Button>
                );
              })
            ) : (
              <Button size="small">{cardbody.label}</Button>
            )}
          </ButtonGroup>
        </Col>
      );
    }
    if (cardbody.type === 'Progress') {
      return (
        <Col className="col" sm={newCardBodySM} md={newCardBodyMD} xl={newCardBodyXL} key={cardbody.label}>
          {cardbody.tooltipText ? (
            <Tooltip placement={cardbody.tooltipPlacement as Placement} label={cardbody.tooltipText}>
              <ProgressBar value={cardbody.value ? parseFloat(cardbody.value) : 0} status={cardbody.status} />
            </Tooltip>
          ) : (
            <ProgressBar value={cardbody.value ? parseFloat(cardbody.value) : 0} status={cardbody.status} />
          )}
        </Col>
      );
    }
    return <Col sm={newCardBodySM} md={newCardBodyMD} xl={newCardBodyXL} key="emptycol" />;
  };

  const displayComponent = () => {
    if (status === 'failed') {
      return <NoData type="error" message="Error occured. Please try after sometime. " customClassName="api-fetching-error" />;
    }
    return (
      <>
        <Row className="card-header" key="card-header">
          {cardHeader.map((card) => displayHeader(card))}
        </Row>
        <Row className="card-body" key="card-body">
          {cardBody.map((card) => displayBodyInfo(card))}
        </Row>
      </>
    );
  };

  return (
    <div className="card-compoent-cls bg-coolgrey-10">
      <Card className={config.className}>
        <CardContent>
          <Container>
            {loading ? (
              <div className="loader-center">
                <SpinnerComponent size="small" label="" />
              </div>
            ) : (
              displayComponent()
            )}
          </Container>
        </CardContent>
      </Card>
    </div>
  );
}

CardComponent.defaultProps = {
  loading: false,
  status: null,
};
