import { createSlice } from '@reduxjs/toolkit';

import {
  fetchTaskInfo, fetchTaskReportAnalysis, fetchTaskInfoFleetLevel, fetchTaskInfoOperatorLevel, fetchTaskInfoFleetLevelCSMFiltered,
  fetchTaskReportByActualInterval, fetchTaskReportByCummulativeInterval,
  startRawTaskReportsSync, fetchRawTaskReportsSyncStatus, fetchRawTaskReports, fetchRawTaskReportsColumnSearch,
  fetchTaskInfoFleetLevelGeoRegionChart, fetchTaskInfoFleetLevelFindingChart,
  fetchPrecomputedRawTaskReports,
  fetchPrecomputedRawTaskReportsColumnSearch,
  fetchTaskInfoOperatorLevelReportReceivedYearly,
  fetchTaskInfoOperatorLevelFindingChart,
  fetchTaskInfoOperatorLevelFindingRateByMsn,
  fetchTaskReportOperatorCard,
  fetchTaskReportWorldWideFleetCard,
} from './taskreportAsyncThunks';
import { TASKS_SLICE } from './constants';
import {
  fetchTaskInfoPending, fetchTaskInfoFulfilled, fetchTaskInfoRejected,
  fetchTaskReportAnalysisPending, fetchTaskReportAnalysisFulfilled, fetchTaskReportAnalysisRejected,
  fetchTaskInfoFleetLevelPending, fetchTaskInfoFleetLevelFulfilled, fetchTaskInfoFleetLevelRejected,
  fetchTaskInfoOperatorLevelPending, fetchTaskInfoOperatorLevelFulfilled, fetchTaskInfoOperatorLevelRejected,
  fetchTaskInfoFleetLevelCSMFilteredPending, fetchTaskInfoFleetLevelCSMFilteredFulfilled, fetchTaskInfoFleetLevelCSMFilteredRejected,
  fetchTaskReportsByActualIntervalPending, fetchTaskReportsByActualIntervalFulfilled, fetchTaskReportsByActualIntervalRejected,
  fetchTaskReportsByCummulativeIntervalPending, fetchTaskReportsByCummulativeIntervalFulfilled, fetchTaskReportsByCummulativeIntervalRejected,
  startRawTaskReportsSyncPending, startRawTaskReportsSyncFulfilled, startRawTaskReportsSyncRejected,
  fetchRawTaskReportsSyncStatusPending, fetchRawTaskReportsSyncStatusFulfilled, fetchRawTaskReportsSyncStatusRejected,
  fetchRawTaskReportsPending, fetchRawTaskReportsFulfilled, fetchRawTaskReportsRejected, fetchRawTaskReportsColumnSearchFulfilled,
  clearRawTaskReportfilterGroupReducer, deleteRawTaskReportfilterGroupReducer, updateRawTaskReportfilterReducer, updateRawTaskReportfilterGroupReducer,
  updateRawTaskReportfilterValueReducer, updateTaskReportPerformanceTuningReducer, clearTaskReportfilterGroupReducer, resetMetaDataReducer,
  fetchTaskInfoFleetLevelGeoRegionChartPending, fetchTaskInfoFleetLevelGeoRegionChartFulfilled, fetchTaskInfoFleetLevelGeoRegionChartRejected,
  fetchTaskInfoFleetLevelFindingChartPending, fetchTaskInfoFleetLevelFindingChartFulfilled, fetchTaskInfoFleetLevelFindingChartRejected,
  fetchTaskInfoOperatorLevelReportReceivedYearlyPending,
  fetchTaskInfoOperatorLevelReportReceivedYearlyFulfilled,
  fetchTaskInfoOperatorLevelReportReceivedYearlyRejected,
  fetchTaskInfoOperatorLevelFindingChartPending,
  fetchTaskInfoOperatorLevelFindingChartFulfilled,
  fetchTaskInfoOperatorLevelFindingChartRejected,
  fetchTaskInfoOperatorLevelFindingRateByMsnPending,
  fetchTaskInfoOperatorLevelFindingRateByMsnFulfilled,
  fetchTaskInfoOperatorLevelFindingRateByMsnRejected,
  precompActualIntervalByTypeInitReducer, precompActualIntervalByTypeFulfilledReducer, precompActualIntervalByTypeRejectedReducer,
  precompCumulativeIntervalByTypeInitReducer, precompCumulativeIntervalByTypeFulfilledReducer, precompCumulativeIntervalByTypeRejectedReducer,
  clearPrevfilterIdReducer,
  fetchPrecomputeRawTaskReportsPending,
  fetchPrecomputeRawTaskReportsFulfilled,
  fetchPrecomputeRawTaskReportsRejected,
  fetchPrecomputeRawTaskReportsColumnSearchFulfilled,
  updatePrecomputeRawTaskReportfilterReducer,
  updatePrecomputeRawTaskReportfilterGroupReducer,
  updatePrecomputeRawTaskReportfilterValueReducer,
  deletePrecomputeRawTaskReportfilterGroupReducer,
  updatePrecomputeTaskReportPerformanceTuningReducer,
  fetchTaskReportOperatorCardPending,
  fetchTaskReportOperatorCardFulfilled,
  fetchTaskReportOperatorCardRejected,
  fetchTaskReportWorldWideFleetCardPending,
  fetchTaskReportWorldWideCardFulfilled,
  fetchTaskReportWorldWideCardRejected,
} from './taskReportReducers';
import { getTaskReportByIntervalDefaultGraphData } from './taskReportUtils';

export const initialState: TaskReportState = {
  loading: false,
  error: '',
  status: 'idle',
  httpStatus: '',
  operator: '',
  performance: {
    filterId: '',
    taskNumber: '',
    table: {
      limit: 0,
      offset: 0,
      filterGroup: [],
    },
  },
  taskInfo: {
    mpd_task_reference: '',
    source_document_reference: '',
    source_document_type: '',
    mpd_task_description: '',
    mpd_task_code: '',
    mpd_100_threshold: '',
    mpd_100_interval: '',
    applicability_text: '',
    is_calculable: '',
  },
  taskReportAnalysis: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    total_tr: '',
    total_ac_reported: '',
    sufficient_reported_data_for_optimization: '',
    sufficent_reported_bar: '',
    sufficient_task_report: '',
    avg_yield: '',
  },
  taskInfoFleetLevel: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    all_climate_regions: [],
    climate_fleet_finding_rate: [],
    fleet_finding_list: [],
    fleet_finding_avg_count: [],
  },
  taskInfoFleetLevelCSMFiltered: {
    loading: false,
    error: '',
    httpStatus: '',
    status: '',
    operator_finding_rate: '',
    world_wide_finding_rate: '',
  },
  // here
  taskInfoFleetCard: {
    loading: false,
    error: '',
    httpStatus: '',
    status: '',
    world_wide_finding_rate: '',
  },
  taskInfoOperatorLevel: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    operator_task_year: [],
    operator_task_year_count: [],
    operator_task_month: [],
    operator_finding_list: [],
    operator_finding_avg_count: [],
    id_aircraft: [],
    all_finding_per_id_aircraft_count: [],
    nil_finding_per_id_aircraft_count: [],
    finding_rate_per_id_aircraft_count: [],
    ffr: '',
    me: '',
  },
  taskReportByActualInterval: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    data: getTaskReportByIntervalDefaultGraphData(['FC', 'FH', 'MONTH']),
  },
  taskReportByCummulativeInterval: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    data: getTaskReportByIntervalDefaultGraphData(['CUMULATIVE_FC', 'CUMULATIVE_FH', 'AGE']),
  },
  rawTaskReportsSync: {
    loading: false,
    error: '',
    status: 'idle',
    retryCount: 0,
  },
  rawTaskReports: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    data: [],
    totalCount: 0,
    searchPairData: {
      columnName: '',
      columnInput: '',
    },
    searchOptions: [],
    filterGroup: [],
  },
  precomputerawTaskReports: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    data: [],
    totalCount: 0,
    searchPairData: {
      columnName: '',
      columnInput: '',
    },
    searchOptions: [],
    filterGroup: [],
  },
};

const taskSlice = createSlice({
  name: TASKS_SLICE,
  initialState,
  reducers: {
    // sync action creators
    clearRawTaskReportfilterGroup: clearRawTaskReportfilterGroupReducer,
    deleteRawTaskReportfilterGroup: deleteRawTaskReportfilterGroupReducer,
    deletePrecomputeRawTaskReportfilterGroup: deletePrecomputeRawTaskReportfilterGroupReducer,
    updateRawTaskReportfilter: updateRawTaskReportfilterReducer,
    updatePrecomputeRawTaskReportfilter: updatePrecomputeRawTaskReportfilterReducer,
    updateRawTaskReportfilterGroup: updateRawTaskReportfilterGroupReducer,
    updatePrecomputeRawTaskReportfilterGroup: updatePrecomputeRawTaskReportfilterGroupReducer,
    updateRawTaskReportfilterValue: updateRawTaskReportfilterValueReducer,
    updatePrecomputeRawTaskReportfilterValue: updatePrecomputeRawTaskReportfilterValueReducer,
    updateTaskReportPerformanceTuning: updateTaskReportPerformanceTuningReducer,
    updatePrecomputeTaskReportPerformanceTuning: updatePrecomputeTaskReportPerformanceTuningReducer,
    clearTaskReportState: clearTaskReportfilterGroupReducer,
    resetMetaData: resetMetaDataReducer,
    precompActualIntervalByTypeInit: precompActualIntervalByTypeInitReducer,
    precompActualIntervalByTypeFulfilled: precompActualIntervalByTypeFulfilledReducer,
    precompActualIntervalByTypeRejected: precompActualIntervalByTypeRejectedReducer,
    precompCumulativeIntervalByTypeInit: precompCumulativeIntervalByTypeInitReducer,
    precompCumulativeIntervalByTypeFulfilled: precompCumulativeIntervalByTypeFulfilledReducer,
    precompCumulativeIntervalByTypeRejected: precompCumulativeIntervalByTypeRejectedReducer,
    clearPrevfilterId: clearPrevfilterIdReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTaskInfo().pending, fetchTaskInfoPending);
    builder.addCase(fetchTaskInfo().fulfilled, fetchTaskInfoFulfilled);
    builder.addCase(fetchTaskInfo().rejected, fetchTaskInfoRejected);
    builder.addCase(fetchTaskReportAnalysis().pending, fetchTaskReportAnalysisPending);
    builder.addCase(fetchTaskReportAnalysis().fulfilled, fetchTaskReportAnalysisFulfilled);
    builder.addCase(fetchTaskReportAnalysis().rejected, fetchTaskReportAnalysisRejected);
    builder.addCase(fetchTaskReportOperatorCard().pending, fetchTaskReportOperatorCardPending);
    builder.addCase(fetchTaskReportOperatorCard().fulfilled, fetchTaskReportOperatorCardFulfilled);
    builder.addCase(fetchTaskReportOperatorCard().rejected, fetchTaskReportOperatorCardRejected);
    builder.addCase(fetchTaskReportWorldWideFleetCard().pending, fetchTaskReportWorldWideFleetCardPending);
    builder.addCase(fetchTaskReportWorldWideFleetCard().fulfilled, fetchTaskReportWorldWideCardFulfilled);
    builder.addCase(fetchTaskReportWorldWideFleetCard().rejected, fetchTaskReportWorldWideCardRejected);
    builder.addCase(fetchTaskInfoFleetLevel().pending, fetchTaskInfoFleetLevelPending);
    builder.addCase(fetchTaskInfoFleetLevel().fulfilled, fetchTaskInfoFleetLevelFulfilled);
    builder.addCase(fetchTaskInfoFleetLevel().rejected, fetchTaskInfoFleetLevelRejected);
    builder.addCase(fetchTaskInfoOperatorLevel().pending, fetchTaskInfoOperatorLevelPending);
    builder.addCase(fetchTaskInfoOperatorLevel().fulfilled, fetchTaskInfoOperatorLevelFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevel().rejected, fetchTaskInfoOperatorLevelRejected);
    builder.addCase(fetchTaskInfoFleetLevelCSMFiltered().pending, fetchTaskInfoFleetLevelCSMFilteredPending);
    builder.addCase(fetchTaskInfoFleetLevelCSMFiltered().fulfilled, fetchTaskInfoFleetLevelCSMFilteredFulfilled);
    builder.addCase(fetchTaskInfoFleetLevelCSMFiltered().rejected, fetchTaskInfoFleetLevelCSMFilteredRejected);
    builder.addCase(fetchTaskReportByActualInterval().pending, fetchTaskReportsByActualIntervalPending);
    builder.addCase(fetchTaskReportByActualInterval().fulfilled, fetchTaskReportsByActualIntervalFulfilled);
    builder.addCase(fetchTaskReportByActualInterval().rejected, fetchTaskReportsByActualIntervalRejected);
    builder.addCase(fetchTaskReportByCummulativeInterval().pending, fetchTaskReportsByCummulativeIntervalPending);
    builder.addCase(fetchTaskReportByCummulativeInterval().fulfilled, fetchTaskReportsByCummulativeIntervalFulfilled);
    builder.addCase(fetchTaskReportByCummulativeInterval().rejected, fetchTaskReportsByCummulativeIntervalRejected);
    builder.addCase(startRawTaskReportsSync().pending, startRawTaskReportsSyncPending);
    builder.addCase(startRawTaskReportsSync().fulfilled, startRawTaskReportsSyncFulfilled);
    builder.addCase(startRawTaskReportsSync().rejected, startRawTaskReportsSyncRejected);
    builder.addCase(fetchRawTaskReportsSyncStatus().pending, fetchRawTaskReportsSyncStatusPending);
    builder.addCase(fetchRawTaskReportsSyncStatus().fulfilled, fetchRawTaskReportsSyncStatusFulfilled);
    builder.addCase(fetchRawTaskReportsSyncStatus().rejected, fetchRawTaskReportsSyncStatusRejected);
    builder.addCase(fetchRawTaskReports().pending, fetchRawTaskReportsPending);
    builder.addCase(fetchRawTaskReports().fulfilled, fetchRawTaskReportsFulfilled);
    builder.addCase(fetchRawTaskReports().rejected, fetchRawTaskReportsRejected);
    builder.addCase(fetchRawTaskReportsColumnSearch().fulfilled, fetchRawTaskReportsColumnSearchFulfilled);
    builder.addCase(fetchTaskInfoFleetLevelGeoRegionChart().pending, fetchTaskInfoFleetLevelGeoRegionChartPending);
    builder.addCase(fetchTaskInfoFleetLevelGeoRegionChart().fulfilled, fetchTaskInfoFleetLevelGeoRegionChartFulfilled);
    builder.addCase(fetchTaskInfoFleetLevelGeoRegionChart().rejected, fetchTaskInfoFleetLevelGeoRegionChartRejected);
    builder.addCase(fetchTaskInfoFleetLevelFindingChart().pending, fetchTaskInfoFleetLevelFindingChartPending);
    builder.addCase(fetchTaskInfoFleetLevelFindingChart().fulfilled, fetchTaskInfoFleetLevelFindingChartFulfilled);
    builder.addCase(fetchTaskInfoFleetLevelFindingChart().rejected, fetchTaskInfoFleetLevelFindingChartRejected);
    builder.addCase(fetchPrecomputedRawTaskReports().pending, fetchPrecomputeRawTaskReportsPending);
    builder.addCase(fetchPrecomputedRawTaskReports().fulfilled, fetchPrecomputeRawTaskReportsFulfilled);
    builder.addCase(fetchPrecomputedRawTaskReports().rejected, fetchPrecomputeRawTaskReportsRejected);
    builder.addCase(fetchPrecomputedRawTaskReportsColumnSearch().fulfilled, fetchPrecomputeRawTaskReportsColumnSearchFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelReportReceivedYearly().pending, fetchTaskInfoOperatorLevelReportReceivedYearlyPending);
    builder.addCase(fetchTaskInfoOperatorLevelReportReceivedYearly().fulfilled, fetchTaskInfoOperatorLevelReportReceivedYearlyFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelReportReceivedYearly().rejected, fetchTaskInfoOperatorLevelReportReceivedYearlyRejected);
    builder.addCase(fetchTaskInfoOperatorLevelFindingChart().pending, fetchTaskInfoOperatorLevelFindingChartPending);
    builder.addCase(fetchTaskInfoOperatorLevelFindingChart().fulfilled, fetchTaskInfoOperatorLevelFindingChartFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelFindingChart().rejected, fetchTaskInfoOperatorLevelFindingChartRejected);
    builder.addCase(fetchTaskInfoOperatorLevelFindingRateByMsn().pending, fetchTaskInfoOperatorLevelFindingRateByMsnPending);
    builder.addCase(fetchTaskInfoOperatorLevelFindingRateByMsn().fulfilled, fetchTaskInfoOperatorLevelFindingRateByMsnFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelFindingRateByMsn().rejected, fetchTaskInfoOperatorLevelFindingRateByMsnRejected);
  },
});

export const {
  clearRawTaskReportfilterGroup,
  deleteRawTaskReportfilterGroup,
  deletePrecomputeRawTaskReportfilterGroup,
  updateRawTaskReportfilter,
  updatePrecomputeRawTaskReportfilter,
  updatePrecomputeRawTaskReportfilterGroup,
  updateRawTaskReportfilterGroup,
  updatePrecomputeRawTaskReportfilterValue,
  updateRawTaskReportfilterValue,
  updateTaskReportPerformanceTuning,
  clearTaskReportState,
  resetMetaData,
  precompActualIntervalByTypeInit,
  precompActualIntervalByTypeFulfilled,
  precompActualIntervalByTypeRejected,
  precompCumulativeIntervalByTypeInit,
  precompCumulativeIntervalByTypeFulfilled,
  precompCumulativeIntervalByTypeRejected,
  clearPrevfilterId,
  updatePrecomputeTaskReportPerformanceTuning,
} = taskSlice.actions;
export default taskSlice;
