/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FETCH_MPD_EMTL_TABLE, CREATE_MPD_EMTL_TABLE, EMTL_TABLE_API_PATH, FETCH_EMTL_FILTER_VALUE, CREATE_EMTL_FILTER, FILTER_API_PATH,
  EMTL_TABLE_EXPORT_API_PATH, CREATE_MPD_EMTL_TABLE_EXPORT, FETCH_MPD_EMTL_TABLE_EXPORT, EMTL_TABLE_EXPORT_TASK_API_PATH,
  FETCH_MPD_EMTL_TABLE_EXPORT_TASK, CREATE_MPD_EMTL_TABLE_EXPORT_TASK, CREATE_MPD_TABLE, FETCH_MPD_TABLE, MPD_TABLE_API_PATH,
  FETCH_TADA_EMTL_FILTER_VALUE, CREATE_TADA_EMTL_FILTER, FILTER_TADA_API_PATH,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';

export const createEmtlTableFilter: any = (colName: string, colVal: string, msnReg: string[], acProgram: string) => {
  const customAction = {
    sliceName: FETCH_EMTL_FILTER_VALUE,
    actionType: CREATE_EMTL_FILTER,
  };
  const apiRequestObj = {
    path: FILTER_API_PATH,
    method: 'post' as Methods,
    body: {
      column_name: colName,
      column_value: colVal,
      ac_program: acProgram,
      msn_regno: msnReg,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createTadaEmtlTableFilter: any = (colName: string, colVal: string, msnReg: string[], modName: string, filterGroup: EmtlTableGroupAction[]) => {
  const customAction = {
    sliceName: FETCH_TADA_EMTL_FILTER_VALUE,
    actionType: CREATE_TADA_EMTL_FILTER,
  };
  const apiRequestObj = {
    path: FILTER_TADA_API_PATH,
    method: 'post' as Methods,
    body: {
      column_name: colName,
      column_value: colVal,
      module_name: modName,
      msn_registration: msnReg,
      filter_group: filterGroup,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdEmtlTableFilter: any = (acMsnRegNo: string[], filterGroup: [], pageSize: number, pageNumber: number, acProgram: string) => {
  const customAction = {
    sliceName: FETCH_MPD_EMTL_TABLE,
    actionType: CREATE_MPD_EMTL_TABLE,
  };

  const apiRequestObj = {
    path: `${EMTL_TABLE_API_PATH}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    method: 'post' as Methods,
    body: {
      maintenance_program: acProgram,
      msn_registration: acMsnRegNo,
      filter_group: filterGroup,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdTable: any = (moduleName: string, acMsnRegNo: string[], filterGroup: [], pageSize: number, pageNumber: number) => {
  const customAction = {
    sliceName: FETCH_MPD_TABLE,
    actionType: CREATE_MPD_TABLE,
  };

  const apiRequestObj = {
    path: `${MPD_TABLE_API_PATH}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    method: 'post' as Methods,
    body: {
      module_name: moduleName,
      msn_registration: acMsnRegNo,
      filter_group: filterGroup,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdEmtlTableExport: any = (acMsnRegNo: string[], acProgram: string, acSeries: string[]) => {
  const customAction = {
    sliceName: FETCH_MPD_EMTL_TABLE_EXPORT,
    actionType: CREATE_MPD_EMTL_TABLE_EXPORT,
  };

  const apiRequestObj = {
    path: EMTL_TABLE_EXPORT_API_PATH,
    method: 'post' as Methods,
    body: {
      maintenance_program: acProgram,
      msn_registration: acMsnRegNo,
      filter_group: [],
      ac_series: acSeries,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createMpdEmtlTableExportTask: any = (
  acProgram: string,
  customizationModule: string,
  acSeries: string[],
  acMsnRegNo: string[],
  issueNumber: string,
  issueDate: string,
  mpdRevision: string,
  revisionDate: string,
) => {
  const customAction = {
    sliceName: FETCH_MPD_EMTL_TABLE_EXPORT_TASK,
    actionType: CREATE_MPD_EMTL_TABLE_EXPORT_TASK,
  };

  const apiRequestObj = {
    path: EMTL_TABLE_EXPORT_TASK_API_PATH,
    method: 'post' as Methods,
    body: {
      customizationModule,
      filterValue: {
        maintenance_program: acProgram,
        ac_series: acSeries,
        msn_registration: acMsnRegNo,
        filter_group: [],
      },
      generalInfoData: {
        issueNumber,
        issueDate,
        mpdRevision,
        revisionDate,
      },
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
