/* eslint-disable @typescript-eslint/no-explicit-any */
import { Methods } from '../../utils/customHooks/apiTypes';
import {
  TASKS_SLICE, FETCH_TASK_INFO, TASK_REPORT_API_PATH, TASK_REPORT_API_POST_METHOD, SMAC_POST_TASK_ASSESSMENT_RAW_TASK_REPORT,
  FETCH_TASK_INFO_COLUMNS, FETCH_TASK_INFO_TABLE_NAME,
  FETCH_TASK_REPORT_ANALYSIS, FETCH_TASK_REPORT_ANALYSIS_COLUMNS, FETCH_TASK_REPORT_ANALYSIS_TABLE_NAME,
  FETCH_TASK_INFO_FLEET_LEVEL, FETCH_TASK_INFO_FLEET_LEVEL_COLUMNS, FETCH_TASK_INFO_FLEET_LEVEL_TABLE_NAME,
  FETCH_TASK_INFO_OPERATOR_LEVEL, FETCH_TASK_INFO_OPERATOR_LEVEL_COLUMNS, FETCH_TASK_INFO_OPERATOR_LEVEL_TABLE_NAME,
  FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL, FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL_COLUMNS, FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL_TABLE_NAME,
  FETCH_TASK_REPORT_BY_CUMMULATIVE_INTERVAL, FETCH_TASK_REPORT_BY_CUMMULATIVE_INTERVAL_TABLE_NAME,
  START_RAW_TASK_REPORTS_SYNC, FETCH_RAW_TASK_REPORTS_SYNC_STATUS,
  FETCH_RAW_TASK_REPORTS, FETCH_RAW_TASK_REPORTS_COLUMNS, FETCH_RAW_TASK_REPORTS_TABLE_NAME, FETCH_RAW_TASK_REPORTS_COLUMN_SEARCH, FETCH_TASK_INFO_FLEET_LEVEL_LW, FETCH_TASK_INFO_FLEET_LEVEL_LW_COLUMNS, FETCH_TASK_INFO_FLEET_LEVEL_LW_TABLE_NAME,
  TASK_REPORT_PRECOMPUTE_API_PATH,
  TASK_REPORT_API_GET_METHOD, FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION, FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART,
  TASK_REPORT_OPERATORCARD_API_PATH,
  FETCH_TASK_INFO_OPERATOR_LEVEL_REPORT_RECEIVED_YEARLY,
  FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART,
  FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN,
  FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL,
  FETCH_PRECOMPUTE_RAW_TASK_REPORTS_COLUMN_SEARCH,
  FETCH_PRECOMPUTE_RAW_TASK_REPORTS,
  FETCH_TASK_REPORT_OPERATOR_CARD,
  FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD,
  TASK_REPORT_WORLDWIDEFLEETCARD_API_PATH,
} from './constants';
import { SCOPES_API_PATH } from '../unscheduledEventsModel/constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { prepareFilterList, preparePrecompFilterList, searchColumnString } from './taskReportUtils';
import { architecture } from '../programAnalysisRepository/PARepositoryAsyncThunk';
import { NEW_ARCH } from '../../components/TaskReportAnalysis/constants';

interface APIRequestObj {
  path: string;
  method:Methods;
  body?: {
  columns: string[];
  table: string;
  filters: string[];
  };
  header?: {
  operator: string;
  };
 }

const generateQueryParams = (primaryScope: string, secondaryScope: string, taskNumber: string, type: string, info: string) => {
  return `primary_scope=${primaryScope}&secondary_scope=${secondaryScope}&task_number=${taskNumber}&type=${type}&info=${info}`;
};

export const fetchTaskInfo: any = (filterId: string, taskNumber: TaskNumberParam, operator: string) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO,
  };
  let apiRequestObj:APIRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_TASK_INFO_COLUMNS,
      table: FETCH_TASK_INFO_TABLE_NAME,
      filters: [
        `id eq ${filterId}`,
        `mpd_task_reference eq ${taskNumber}`,
      ],
    },
    header: {
      operator,
    },
  };
  if (architecture() === NEW_ARCH) {
    apiRequestObj = {
      path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${filterId}/data?primary_scope=task_report_analysis&secondary_scope=meta_data&task_number=${taskNumber}`,
      method: TASK_REPORT_API_GET_METHOD,
    };
  }

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskReportAnalysis: any = (params: FetchTaskReportAnalysisParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_REPORT_ANALYSIS,
  };
  const apiRequestObj:APIRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_TASK_REPORT_ANALYSIS_COLUMNS,
      table: FETCH_TASK_REPORT_ANALYSIS_TABLE_NAME,
      filters: [
        `id eq ${params?.filterId}`,
        `mpd_task_reference eq ${params?.taskNumber}`,
      ],
    },
    header: {
      operator: params?.operator,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoFleetLevel: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_FLEET_LEVEL,
  };

  const apiRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_TASK_INFO_FLEET_LEVEL_COLUMNS,
      table: FETCH_TASK_INFO_FLEET_LEVEL_TABLE_NAME,
      filters: [
        `id eq ${params?.filterId}`,
        `mpd_task_reference eq ${params?.taskNumber}`,
      ],
    },
    header: {
      operator: params?.operator,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoFleetLevelCSMFiltered: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_FLEET_LEVEL_LW,
  };
  const apiRequestObj:APIRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_TASK_INFO_FLEET_LEVEL_LW_COLUMNS,
      table: FETCH_TASK_INFO_FLEET_LEVEL_LW_TABLE_NAME,
      filters: [
        `id eq ${params?.filterId}`,
        `mpd_task_reference eq ${params?.taskNumber}`,
      ],
    },
    header: {
      operator: params?.operator,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoOperatorLevel: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_OPERATOR_LEVEL,
  };
  const apiRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_TASK_INFO_OPERATOR_LEVEL_COLUMNS,
      table: FETCH_TASK_INFO_OPERATOR_LEVEL_TABLE_NAME,
      filters: [
        `id eq ${params?.filterId}`,
        `mpd_task_reference eq ${params?.taskNumber}`,
        'is_calculable nlike LSDR_TASK',
      ],
    },
    header: {
      operator: params?.operator,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskReportByActualInterval: any = (params: FetchTaskReportByActualIntervalParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL,
  };
  const apiRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL_COLUMNS,
      table: FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL_TABLE_NAME,
      filters: [
        `id eq ${params?.filterId}`,
        `mpd_task_reference eq ${params?.taskNumber}`,
        'is_calculable nlike LSDR_TASK',
      ],
    },
    header: {
      operator: params?.operator,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchPrecompIntervalByType: any = (params: FetchTaskReportByActualIntervalParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL,
  };
  const {
    taskNumber, intervalType, intervalInfo, filterId,
  } = params || {};
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${filterId}/data?${generateQueryParams('task_report_analysis', 'interval_graph', taskNumber, intervalType, intervalInfo)}`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskReportByCummulativeInterval: any = (params: FetchTaskReportByActualIntervalParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_REPORT_BY_CUMMULATIVE_INTERVAL,
  };
  const apiRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_TASK_REPORT_BY_ACTUAL_INTERVAL_COLUMNS,
      table: FETCH_TASK_REPORT_BY_CUMMULATIVE_INTERVAL_TABLE_NAME,
      filters: [
        `id eq ${params?.filterId}`,
        `mpd_task_reference eq ${params?.taskNumber}`,
        'is_calculable nlike LSDR_TASK',
      ],
    },
    header: {
      operator: params?.operator,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const startRawTaskReportsSync: any = (filterId: string, taskNumber?: string) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: START_RAW_TASK_REPORTS_SYNC,
  };
  const apiRequestObj = {
    path: `${SCOPES_API_PATH}/${filterId}/datasets/${SMAC_POST_TASK_ASSESSMENT_RAW_TASK_REPORT}/mpds/${taskNumber}?no-data=true`,
    method: 'post' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchRawTaskReportsSyncStatus: any = (filterId: string, taskNumber?: string) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_RAW_TASK_REPORTS_SYNC_STATUS,
  };
  const apiRequestObj = {
    path: `${SCOPES_API_PATH}/${filterId}/datasets/${SMAC_POST_TASK_ASSESSMENT_RAW_TASK_REPORT}/mpds/${taskNumber}/status`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchRawTaskReports: any = (params: FetchRawTaskReportsParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_RAW_TASK_REPORTS,
  };
  const apiRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: FETCH_RAW_TASK_REPORTS_COLUMNS,
      table: FETCH_RAW_TASK_REPORTS_TABLE_NAME,
      filters: prepareFilterList(params),
      limit: params?.limit,
      offset: params?.offset,
    },
    header: {
      operator: params?.operator,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchPrecomputedRawTaskReports: any = (params: FetchRawTaskReportsParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_PRECOMPUTE_RAW_TASK_REPORTS,
  };
  const filterString = preparePrecompFilterList(params);

  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?task_number=${params?.taskNumber}&primary_scope=task_report_analysis&secondary_scope=task_report_raw_task_table_data&offset=${params?.offset}&limit=${params?.limit}&columns=actual_interval_months,actual_interval_flight_cycles,actual_interval_flight_hours,finding_type_description,id_aircraft,ac_series,corrective_action`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  if (filterString) {
    apiRequestObj.path = `${apiRequestObj.path}&filters=${filterString}`;
  }
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchPrecomputedRawTaskReportsColumnSearch: any = (params: FetchRawTaskReportsColumnSearchParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_PRECOMPUTE_RAW_TASK_REPORTS_COLUMN_SEARCH,
  };
  const filterString = searchColumnString(params);
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?task_number=${params?.taskNumber}&primary_scope=task_report_analysis&secondary_scope=task_report_raw_task_table_data&columns=${params?.searchColumn?.columnName}&filters=${filterString}&distinct=1`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchRawTaskReportsColumnSearch: any = (params: FetchRawTaskReportsColumnSearchParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_RAW_TASK_REPORTS_COLUMN_SEARCH,
  };
  const filterString = searchColumnString(params);
  const apiRequestObj = {
    path: TASK_REPORT_API_PATH,
    method: TASK_REPORT_API_POST_METHOD,
    body: {
      columns: [params?.searchColumn.columnName],
      table: FETCH_RAW_TASK_REPORTS_TABLE_NAME,
      filters: [
        `id eq ${params?.filterId}`,
        `mpd_task_reference eq ${params?.taskNumber}`,
        filterString,
      ],
      distinct: 1,
    },
    header: {
      operator: params?.operator,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

// API only for newarchitecture FleetLevel-finding_rate_by_geo_region,Fleet-wide number of findings vs Finding Type

export const fetchTaskInfoFleetLevelGeoRegionChart: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&secondary_scope=fleet_region_vs_finding_rate&task_number=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoFleetLevelFindingChart: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&secondary_scope=fleet_avg_findings_per_msn&task_number=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
// API only for newarchitecture Operator Level -Operator Task Reports received per year,number of findings vs Finding Type,Number of Task Reports and associated Finding rate by MSN
export const fetchTaskInfoOperatorLevelReportReceivedYearly: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_OPERATOR_LEVEL_REPORT_RECEIVED_YEARLY,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&task_number=${params?.taskNumber}&secondary_scope=operator_task_report_per_year`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
export const fetchTaskInfoOperatorLevelFindingChart: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&task_number=${params?.taskNumber}&secondary_scope=operator_avg_findings_per_msn`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTaskInfoOperatorLevelFindingRateByMsn: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/data?primary_scope=task_report_analysis&task_number=${params?.taskNumber}&secondary_scope=number_of_task_reports_and_associated_finding_rate_by_msn`,
    method: TASK_REPORT_API_GET_METHOD,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
// thunk for new architecture task report analysis page operator card
export const fetchTaskReportOperatorCard: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_REPORT_OPERATOR_CARD,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/${TASK_REPORT_OPERATORCARD_API_PATH}=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
// thunk for new architecture task report analysis page Wrold Wide fleet  card
export const fetchTaskReportWorldWideFleetCard: any = (params: FetchTaskInfoFleetLevelParams) => {
  const customAction = {
    sliceName: TASKS_SLICE,
    actionType: FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD,
  };
  const apiRequestObj = {
    path: `${TASK_REPORT_PRECOMPUTE_API_PATH}/${params?.filterId}/${TASK_REPORT_WORLDWIDEFLEETCARD_API_PATH}=${params?.taskNumber}`,
    method: TASK_REPORT_API_GET_METHOD,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
