import React from 'react';
import {
  Col, IconButton, Row, List, ListItem, ListItemText,
} from '@airbus/components-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack, ArrowForward, Chevronright } from '@airbus/icons/react';
import './MpdRepositoryFilterPanel.scss';
import { itemsInLeftDrawer } from '../../container/MpdRepository/mpdRepositoryUtils';
import { RootState } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { setFilterPanelOpen } from '../../models/mpdRepositoryModel/mpdRepositorySlice';

const MpdRepositoryFilterPanel = function Repository() {
  const {
    RepositoryFilterPanalOpen: filterStatus,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loc = useLocation();

  const createItemsInPanel = () => {
    return (itemsInLeftDrawer.map((item) => {
      return (
        <>
          <Row key={item.key}>
            <div className="repo-panel-sub-header"><b style={{ color: 'grey' }}>{item.title}</b></div>
          </Row>
          <List>
            {item.subItems.map((subItem) => (
              <ListItem
                key={subItem.key}
                disabled={subItem.disabled}
                onClick={() => navigate(`/repository/${subItem.path}`)}
                selected={subItem.path !== '' && loc.pathname.includes(subItem.path)}
              >
                <ListItemText>{subItem.title}</ListItemText>
                <Chevronright className="icon-cls" />
              </ListItem>
            ))}
          </List>
        </>
      );
    }));
  };
  return (
    <div className="repository-filter-panel">
      {!filterStatus
        ? (
          <IconButton
            variant="primary"
            className="repo-arrow-button-close"
            size="small"
            onClick={() => dispatch(setFilterPanelOpen(!filterStatus))}
            aria-label="open-repo-filter-drawer"
          >
            <ArrowForward />
          </IconButton>
        )
        : (
          <>
            <Row>
              <Col>
                <div className="repo-panel-header"><b style={{ color: 'grey' }}>Repository</b></div>
              </Col>
              <Col>
                <IconButton
                  variant="primary"
                  className="repo-arrow-button-open"
                  size="small"
                  onClick={() => dispatch(setFilterPanelOpen(!filterStatus))}
                  aria-label="close-repo-filter-drawer"
                >
                  <ArrowBack />
                </IconButton>
              </Col>
            </Row>
            {createItemsInPanel()}
          </>
        )}
    </div>
  );
};

export default MpdRepositoryFilterPanel;
