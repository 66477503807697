import React, {
  useState, Dispatch, SetStateAction, useEffect,
} from 'react';
import {
  Drawer,
  Button,
  Stack,
  IconButton,
  Container,
  Row,
  Col,
  Typography,
  Radio,
  Spinner,
  Flex,
} from '@airbus/components-react';
import { ArrowBack, ArrowForward } from '@airbus/icons/react';
// eslint-disable-next-line
import { ComboBoxProps } from '@airbus/components-react/dist/components/ComboBox';
import { createMpdFilter } from '../../models/mpdFilterDrawerModel/mpdAsyncThunk';
import './MpdFilterPanel.scss';
import {
  updateAcSeries, updateEffectiveMaintenance, updateMsnRno, updateMsnRnoSearch,
} from '../../models/mpdFilterDrawerModel/mpdDrawerSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import { clearAllEmtlTableFilter, resetMpdEmtlTableValue, updateEmtlTablePageNumber } from '../../models/mpdEmtlTableModel/mpdEmtlTableSlice';
import { createMpdGeneralInfo } from '../../models/effectiveMaintenenceTaskListModel/effectiveMaintenenceTaskListAsyncThunk';
import { RADIO_OPTIONS } from '../../models/mpdFilterDrawerModel/constants';
import SelectComboBox from '../Shared/SelectComboBox/SelectComboBox';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';

type MpdFilterPanelProps = {
  showContent: Dispatch<SetStateAction<boolean>>;
  isBannerShown: boolean;
};

/* eslint-disable operator-linebreak */
const MpdFilterPanel = function MpdFilterPanel(props: MpdFilterPanelProps) {
  const [open, setOpen] = useState(true);
  const acSeriesHeader = 'Aircraft Series';
  const msnRegNoHeader = 'MSN\u00a0\u00a0\u00a0\u00a0\u00a0Registration Number';
  const [MsnRegState, setMsnRegState] = useState<string[]>([]);
  const [btnState, setBtnState] = useState(true);
  const { showContent, isBannerShown } = props;
  const dispatch = useAppDispatch(); const {
    AircraftSeriesType: acType, AircraftFilters: acFilters, loading: acLoading, effectiveMainenance: acEffMaintenance, MsnAndRegFilters: acMsnRegNoFilter, AircraftMsnRnoType: acMsnRegNo,
  } = useAppSelector((state: RootState) => state.mpdDrawer);
  const acProgram = secureLS.get('ac_program');

  useEffect(() => {
    dispatch(createMpdFilter(acProgram)());
    dispatch(updateEffectiveMaintenance(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // To enable submit button
    if (acType.length && acEffMaintenance && acMsnRegNo.length) {
      setBtnState(false);
    } else {
      setBtnState(true);
    }
  }, [acType, acEffMaintenance, acMsnRegNo]);

  useEffect(() => {
    const accum: string[] = [];
    const msnRno = acMsnRegNoFilter.filter((msnreg) => acType.indexOf(Object.values(msnreg)[0]) !== -1 || acType.indexOf('All') !== -1).reduce((acc, msn) => {
      acc.push(...Object.values(msn)[1]);
      return acc;
    }, accum).sort();
    setMsnRegState(msnRno);
    dispatch(updateMsnRno(msnRno));
  }, [acType, acMsnRegNoFilter, dispatch]);

  const handleComboOnChangeEvent = (options: string[], value: string[], onChangeValue: string[] | string, dispatchActionType: string) => {
    const actionType = dispatchActionType === 'updateAcSeries' ? updateAcSeries : updateMsnRno;
    dispatch(actionType(onChangeValue as FilterEffectivityType[]));
  };

  // eslint-disable-next-line
  let propsComboAcSeries: ComboBoxProps = {
    limitChips: 2,
    options: acFilters,
    value: acType,
    'aria-label': 'mpd-ac-series',
    onChange: (value) => {
      handleComboOnChangeEvent(acFilters, acType, value as never[], 'updateAcSeries');
    },
    getChipLabel: (option: unknown): string => option as string,
    getOptionLabel: (option: unknown): string => option as string,
    getOptionValue: (option: unknown): string => option as string,
  };

  // eslint-disable-next-line
  let propsComboMsnRegNo: ComboBoxProps = {
    limitChips: 2,
    options: MsnRegState,
    value: acMsnRegNo,
    'aria-label': 'mpd-msn-rno',
    onChange: (value) => {
      handleComboOnChangeEvent(MsnRegState, acMsnRegNo, value as never[], 'updateMsnRno');
    },
    getChipLabel: (option: unknown): string => (option as string).replace(' ', '\u00a0'),
    getOptionLabel: (option: unknown): string => (option as string).replace(' ', '\u00a0'),
    getOptionValue: (option: unknown): string => option as string,
  };

  const onSubmitHandler = () => {
    setOpen(false);
    showContent(true);
    dispatch(clearAllEmtlTableFilter('mpdSubmit'));
    dispatch(createMpdGeneralInfo('EMTL')());
    dispatch(updateMsnRnoSearch());
    dispatch(updateEmtlTablePageNumber(1));
    dispatch(resetMpdEmtlTableValue());
  };

  return (
    <div className="mpd-filter-drawer-cls">
      <IconButton
        variant="primary"
        className="arrow-button"
        size="small"
        onClick={() => {
          setOpen(true);
          setBtnState(true);
        }}
        aria-label="open-filter-drawer"
      >
        <ArrowForward />
      </IconButton>
      <Drawer open={open} anchor="left" disableBackdropClick>
        <div className={`mpd-filter-drawer ${(isBannerShown ? 'banner-open' : '')}`}>
          <Container>
            <Row alignItems="center">
              <Col>
                <Typography variant="large">Filters</Typography>
              </Col>
              <Col xs={4} md={2}>
                <IconButton
                  className="arrow-button"
                  variant="primary"
                  size="small"
                  onClick={() => setOpen(false)}
                  aria-label="close-filter-drawer"
                >
                  <ArrowBack />
                </IconButton>
              </Col>
            </Row>
          </Container>
          {acLoading ?
            <Spinner size="small" className="mpd-spinner-cls" aria-label="mpdSpinner" />
            :
            (
              <>
                <Stack spacing="2-x" className="w-288">
                  <Typography className="effectivity-cls" variant="small">EFFECTIVITY</Typography>
                  <SelectComboBox showTotalNumber comboProps={propsComboAcSeries} dropdownHeader={acSeriesHeader} />
                  <SelectComboBox showTotalNumber comboProps={propsComboMsnRegNo} dropdownHeader={msnRegNoHeader} />
                  <Typography className="effMaintanance-cls" variant="small">EFFECTIVE MAINTENANCE TASK</Typography>
                  <Flex direction="column" spacing="1-x" className="radio-cls-style" aria-label="task-radio">
                    {RADIO_OPTIONS.map((option) => (
                      <>
                        <Radio
                          className="effective-radio-cls"
                          name={option.value}
                          key={option.value}
                          value={option.value}
                          label={option.label}
                          checked={option.value === acEffMaintenance}
                          disabled={option.disabled}
                          onChange={() => {
                            dispatch(updateEffectiveMaintenance(option.value));
                          }}
                        />
                        {option.description && <p key={`p${option.value}`} className={(option.value === acEffMaintenance) ? 'radioChecked-cls' : 'effective-radio-des-cls'}>{option.description}</p>}
                      </>
                    ))}
                  </Flex>
                </Stack>
                <Button
                  variant="primary"
                  className="submit-btn"
                  aria-label="mpd-submit"
                  disabled={btnState}
                  onClick={onSubmitHandler}
                >
                  Submit
                </Button>
              </>
            )}
        </div>
      </Drawer>
    </div>
  );
};

export default MpdFilterPanel;
